@import '../styles/vars'

.layout
  height: 100%
  &__inner
    display: flex
    flex-direction: column
    height: 100%
  &__header
    width: 100%
    margin-bottom: 100px
  &__body
    flex: 1 0 auto
    min-height: calc(100vh - 177px)
    padding-bottom: 100px
  &__footer
    width: 100%
    flex: 0 0 auto

.maintenance
  width: 100%
  height: calc( 100vh - 202px - 192px)
  display: flex
  align-items: center
  justify-content: center
  font-weight: 500
  font-size: 24px
  color: $text-light-secondary
  text-align: center

@media (max-width: $small-desktop-width)
  .maintenance
    height: calc( 100vh - 202px - 275px)

@media (max-width: $tablet-width)
  .layout
    &__header
      margin-bottom: 40px
  .maintenance
    height: calc( 100vh - 142px - 251px)

@media (max-width: $mobile-width)
  .layout
    &__body
      padding-bottom: 60px
  .maintenance
    height: calc( 100vh - 142px - 491px)
