@import "../../styles/vars"

.menu
  max-width: 660px
  width: 100%
  &__inner
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
  &__item
    cursor: pointer
    font-weight: 500
    font-size: 16px
    letter-spacing: 0.02em
    display: flex
    gap: 5px
    align-items: center
    transition: all 0.3s
    &-staking
      position: relative
    &-img
      width: 24px
      height: 24px
    &._disabled
      opacity: 0.4
      pointer-events: none
    &._selected
      pointer-events: none
      color: #00BFFF
    &:hover
      color: #00BFFF

@media (max-width: $small-desktop-width-header)
  .menu
    position: fixed
    top: 0px
    left: 0px
    height: 100vh
    max-width: 100vw
    width: 100vw
    background: #0A1327
    transform: translateY(-100%)
    transition: transform 0.3s
    overflow: auto
    &__wallet
      align-self: center
      font-size: 14px
    &__inner
      margin-bottom: 24px
      padding: 16px
      padding-top: 100px
      flex-direction: column
      justify-content: center
      align-items: start
      gap: 30px
    &._open
      transform: translateY(0%)
    &__item
      font-size: 28px
      text-transform: uppercase
      font-weight: 600
      flex-direction: row-reverse
      gap: 10px
