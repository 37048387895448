@import '../../styles/vars.sass'

.timer
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 18px
  &__tba
    font-weight: 600
    font-size: 36px
    margin-bottom: 38px
  &__list
    display: flex
    gap: 18px
    margin-left: -14px
  &__item
    width: 70px
    text-align: center
    &-value
      font-weight: 600
      font-size: 36px
      line-height: 100%
      margin-bottom: 10px
    &-key
      font-weight: 600
      font-size: 13px
      line-height: 100%
      text-align: center
      text-transform: uppercase
      color: $secondary

@media (max-width: $tablet-width)
  .timer
    &__list
      gap: 12px
      margin-left: 0px
    &__item
      width: 65px
      &-value
        font-size: 28px

@media (max-width: $mobile-width)
  .timer
    &__title
      font-size: 14px
      line-height: 100%
    &__list
      justify-content: center
