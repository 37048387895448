@import '../../../styles/mixins'
@import '../../../styles/vars'
.ton-button
  position: relative
  cursor: pointer
  &._disabled
    pointer-events: none
    opacity: .5
  &__badge
    +text-h6()
    position: absolute
    z-index: 10
    left: 50%
    transform: translateX(50%)
    transform: translateY(50%)
    padding: 0 4px
    color: $t-white
    background-image: linear-gradient(to right, var($t-cyan, $t-blue))
    letter-spacing: 0.05em
    border-radius: 4px
  &__content
    cursor: pointer
    border-radius: 8px
    padding: 8px 20px
    color: $t-white
    &._border
      border: 1px solid $t-white
    &._accent
      background-image: linear-gradient(to right, $t-cyan, $t-blue)
      &:hover
        background-image: linear-gradient(to right, $t-cyan, $t-cyan)
    &._primary
      &:hover
        color: $t-black
        background-image: linear-gradient(to right, $t-white, $t-white)
    &._purple
      background-image: linear-gradient(to right, $t-blue-pale, $t-purple-pale)
      &:hover
        background-image: linear-gradient(to right, $t-blue-pale, $t-blue-pale)
    &._disabled
      pointer-events: none
      color: $t-gray
    &._red
      color: rgb(184, 62, 62)
      border: 1px solid rgb(184, 62, 62)
      transition: all .4s
      &:hover
        background: rgb(184, 62, 62)
        color: $t-white
  &__text
    font-weight: 500
    text-align: center
