@import '../../../styles/mixins'
@import '../../../styles/vars'

.stacking-view
  display: flex
  flex-direction: column
  &__buttons
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 10px
    justify-content: center
  &._loader
    filter: blur(5px)
  &__loader
    position: absolute
    width: 100%
    height: 100%
    display: flex
    align-items: center
    z-index: 5
    justify-content: center
  &__title
    +text-h4()
    color: $t-white
    font-weight: 700
    margin-bottom: 16px
  &__info
    display: flex
    align-items: center
    margin-bottom: 16px
    &-item
      margin-right: 64px
    &-title
      text-transform: uppercase
      +text-p3()
      color: $t-gray
      margin-bottom: 8px
    &-text
      +text-h3()
      color: $t-white
  &__total
    display: flex
    flex-direction: column
    gap: 12px
    margin-bottom: 16px
    &-title
      text-transform: uppercase
      +text-h3()
      color: $t-white
    &-decs
      display: flex
      justify-content: space-between
      &-title
        text-transform: uppercase
        +text-p3()
        color: $t-gray
      &-info
        text-transform: uppercase
        +text-p3()
        color: $t-gray
        &._text-green
          color: $t-green
  &__lock-time
    +text-p3()
    color: $t-gray
    margin-bottom: 8px
    text-transform: uppercase
  &__select-line
    margin-bottom: 16px
  &__amount-input
    +text-p3()
    margin-bottom: 16px
    color: $t-white
  &__badge
    display: inline-block
    margin-left: 10px
    &._pointer
      cursor: pointer
  &__tiers
    display: flex
    position: relative
    align-items: center
    margin-bottom: 16px
  &__grid
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    gap: 8px
    width: 100%
    @media (max-width: 410px)
      grid-template-columns: repeat(1, minmax(0, 1fr))
    &-item
      display: flex
      flex-direction: column
      gap: 4px
      padding: 16px
      background: rgba(18, 194, 202, 0.06)
      border-radius: 16px
      @media (max-width: 410px)
        align-items: center
      &-title
        +text-p3()
        color: $t-white
        display: flex
      &-text
        +text-p3()
        color: $t-gray
  &__img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    @media (max-width: 410px)
      transform: rotate(90deg)translate(-50%, 50%)
