@import '../../../styles/vars'

.project-pause
  display: flex
  flex-direction: column
  align-items: center
  &__icon
    margin-bottom: 18px
  &__desc
    font-weight: 600
    font-size: 20px
    line-height: 150%
    text-align: center

@media (max-width: $mobile-width)
  .project-pause
    &__desc
      font-weight: 500
      font-size: 18px
