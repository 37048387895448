@import "../../styles/vars"

.advantages
  &__title
    padding-top: 130px
    font-weight: 500
    font-size: 40px
    text-align: center
    margin-bottom: 40px
    line-height: 100%
    color: $light
  &__item
    display: flex
    flex-direction: column
    gap: 20px
    width: 316px
  &__img
    width: 48px
    height: 48px
  &__name
    display: flex
    min-height: 41px
    height: 100%
    align-items: baseline
    font-weight: 700
    font-size: 16px
    line-height: 130%
    letter-spacing: -0.01em
    text-transform: uppercase
    color: $white
  &__text
    color: $light
    opacity: 0.4
    font-weight: 400
    font-size: 16px
    line-height: 140%
    letter-spacing: -0.01em

@media (max-width: $small-desktop-width)
  .advantages
    &__item
      width: 266px
    &__name
      min-height: 63px
      align-items: baseline

@media (max-width: $tablet-width)
  .advantages
    &__title
      padding-top: 60px
      font-size: 26px
      line-height: 120%
      margin-bottom: 50px
    &__item
      width: 100%
    &__name
      min-height: auto
      height: auto

@media (max-width: $mobile-width)
  .advantages
    margin: 0 -16px
