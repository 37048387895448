@import '../../../styles/vars.sass'

.project-news-item
  display: block
  width: 100%
  border-radius: 40px
  background: $dark
  overflow: hidden
  margin-bottom: 18px
  &:last-child
    margin-bottom: 0
  &:hover .project-news-item__title
    color: $active
  &__banner
    background-color: #1A2039
    background-repeat: no-repeat
    background-size: cover
    background-position: 50%
    height: 240px
  &__inner
    padding: 14px 36px 20px
  &__title *
    font-weight: 500
    font-size: 20px
    line-height: 140%
    &:not(:has(*))
      transition: all 0.25s
  &__desc
    font-size: 14px
    line-height: 140%
    color: $text-light-secondary

@media (max-width: $small-desktop-width)
  .project-news-item
    width: 315px
    min-width: 315px
    border-radius: 20px
    margin-bottom: 0
    &__banner
      height: 175px
    &__inner
      padding: 14px
      padding-bottom: 11px
    &__title
      font-size: 18px

@media (max-width: $mobile-width)
  .project-news-item
    &__inner
      padding: 14px 14px 18px
    &__banner
      height: 160px
