@import '../../../styles/vars.sass'

.refund-modal
  &__row-icon
    max-width: 326px
  &__head
    display: flex
    flex-direction: column
    align-items: center
    gap: 18px
  &__body
    padding-right: 20px
  &__title
    font-weight: 600
    font-size: 36px
    line-height: 140%
    text-align: center
  &__desc
    font-weight: 500
    line-height: 140%
    margin-bottom: 36px
    text-align: center
    max-width: 90%
  &__row
    display: flex
    align-items: baseline
    gap: 17px
    margin-bottom: 36px
    &:first-child
      align-items: center
      & .refund-modal__row-title
        margin-bottom: 0
    &-title
      font-weight: 500
      margin-bottom: 18px
      line-height: 140%
  &__point
    width: 50px
    height: 50px
    display: flex
    align-items: center
    justify-content: center
    background: $secondary-darker
    font-weight: 800
    font-size: 24px
    line-height: 140%
    border-radius: 12px

@media (max-width: $mobile-width)
  .refund-modal
    &__head
      gap: 12px
    &__title
      font-size: 28px
    &__desc
      font-size: 14px
    &__point
      width: 36px
      height: 36px
      border-radius: 8px
      font-size: 16px
    &__row
      gap: 10px
      margin-bottom: 12px
      &-title
        font-size: 14px
        line-height: 130%
        margin-bottom: 12px
      &-icon
        max-width: 100%
