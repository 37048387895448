@import '../../../styles/mixins'
@import '../../../styles/vars'

.badge
  padding: 4px
  border-radius: 6px
  &._primary
    background: rgba(0, 152, 255, 1)
  &._green
    background: rgba(16,170,68,0.3)
  &._red-contrast
    background: $t-red
  &._green-contrast
    background: $t-green
  &._default
    background: rgba(0, 152, 255, 1)
  &__title
    font-size: 10px
    font-weight: 600
    line-height: 1.2
    transition-property: all
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
    transition-duration: 150ms
    &._primary
      color: $t-blue
    &._green
      color: $t-green
    &._red-contrast
      color: $t-white
    &._green-contrast
      color: $t-white
    &._default
      color: $t-white
