@import "../../styles/vars"

.referrer
  &__loader
    height: 600px
  &__project
    &-info
      margin-bottom: 36px
    &-title
      margin-bottom: 40px

@media (max-width: $tablet-width)
  .referrer
    &__project
      &-info
        margin-bottom: 40px

@media (max-width: $mobile-width)
  .referrer
    &__project
      &-info
        background: $dark
        border: 1px solid $outline
        margin-bottom: 28px
        border-radius: 20px
      &-title
        padding: 14px
        padding-bottom: 16px
        margin-bottom: 0
        border-bottom: 1px solid $outline
      &-timeline
        border: none
