@import '../../styles/vars.sass'

$input-gap: 18px

.social-activities
  &__title
    font-weight: 600
    font-size: 36px
    line-height: 120%
    text-align: center
    margin-bottom: 18px
  &__subtitle
    font-size: 16px
    line-height: 100%
    text-align: center
    margin-bottom: 40px
    color: $secondary
  &__row
    position: relative
    margin-bottom: 36px
    &-title
      font-size: 16px
      line-height: 100%
      margin-bottom: 18px
      display: flex
      gap: 5px
  &__list
    display: flex
    flex-wrap: wrap
    gap: 10px
    &-item
      display: block
      height: 48px
      padding: 0 24px
      display: flex
      gap: 10px
      font-weight: 600
      font-size: 16px
      line-height: 100%
      background: $secondary-darker
      border-radius: 18px
      align-items: center
      color: $text-light-secondary
      transition: all .3s ease
      &-icon
        width: 24px
        height: 24px
        & path
          transition: all .3s ease
      &:hover
        color: $light
        background: $active
        & path
          fill: $light
  &__desc
    font-size: 16px
    line-height: 140%
    text-align: center
    color: $secondary
  &__form
    &-group
      display: flex
      justify-content: space-between
      gap: $input-gap
      margin-bottom: $input-gap
      & > .social-activities__form-input
        max-width: calc(50% - $input-gap / 2)
    &-input
      border-radius: 18px
      background-color: $dark
      padding: 16px 56px 16px 31px
      padding-right: 56px
      font-size: 16px
      line-height: 100%
      letter-spacing: 0.02em
      width: 100%
      border: 1px solid $secondary
      outline: none
      color: $text-primary
      &._file
        padding: 11px 31px
      &::placeholder
        color: $secondary
        font-size: 16px
        line-height: 100%
        letter-spacing: 0.02em
      &-wrapper
        display: flex
        position: relative
        width: 100%
      &-mask
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        color: $secondary
        padding: 16px 0px
        height: 100%
        top: 50%
        left: 16px
        transform: translateY(-50%)
        &._active
          color: $text-primary
      &-icon
        position: absolute
        top: 50%
        right: 26px
        width: 24px
        height: 24px
        transform: translateY(-50%)
    &-submit
      width: 356px
    &-error
      position: absolute
      bottom: -25%
      left: 10px
      font-weight: 500
      color: #FF5733
  &__footer
    text-align: center

@media (max-width: $tablet-width)
  .social-activities
    &__title
      font-size: 28px
    &__subtitle
      font-size: 14px
    &__row
      width: 100%
      margin-bottom: 30px
      &-title
        font-size: 14px
        margin-bottom: 10px
    &__list
      &-item
        gap: 8px
        padding: 12px 16px
        font-size: 14px
      &-icon
        width: 22px
        height: 22px
    &__form
      &-input
        padding: 13px 56px 13px 31px
        &._file
          padding: 8px 31px
        &-mask
          padding: 13px 0px
        &::placeholder
          font-size: 14px

@media (max-width: $mobile-width)
  .social-activities
    &__title
      font-size: 24px
      line-height: 130%
      max-width: 70%
      margin-left: auto
      margin-right: auto
    &__subtitle
      line-height: 140%
      margin-bottom: 28px
    &__row
      width: 100%
      margin-bottom: 28px
      &-title
        line-height: 140%
        margin-bottom: 14px
    &__list
      gap: 8px
      &-item
        gap: 6px
        padding: 10px 14px
    &__form
      &-submit
        width: 100%
      &-group
        flex-wrap: wrap
        & > .social-activities__form-input
          max-width: 100%
