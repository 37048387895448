@import '../../../styles/mixins'
@import '../../../styles/vars'

.claim-progress
  display: flex
  flex-direction: column
  &._blur
    filter: blur(10px)
  &__no-data
    padding: 10px
    &-text
      +text-p1()
  &__title
    +text-h4()
    color: $t-white
    font-weight: 700
    margin-bottom: 16px
  &__items
    display: none
    &._open
      display: block
  &__item
    border-top: 1px solid rgba(88, 101, 121, 0.24)
    padding-top: 8px
    margin-top: 5px
    &-title
      +text-p2()
      color: $t-white
      font-weight: 700
      margin-bottom: 16px
  &__claim
    margin-top: 8px
    &._border
      border-top: 1px solid rgba(88, 101, 121, 0.24)
      padding-top: 6px
      margin-top: 10px
  &__more-info
    margin-top: 10px
    display: flex
    align-items: center
    gap: 10px
    color: $t-blue
    cursor: pointer
    +text-p2()
    &-img
      transition: .7s
      transform: rotate(180deg)
      &._transform
        transform: rotate(0deg)
        transition: .7s
  &__button
    cursor: pointer
    border-radius: 10px
    margin-right: 16px
    transition: .7s
    &:hover
      background: #cecece69
.claim-info
  display: flex
  align-items: center
  &__img
    cursor: pointer
    &:hover
      filter: contrast(400%) hue-rotate(90deg)

.spinner
  animation: rotate 2s linear infinite
  z-index: 2
  margin-right: 10px
  width: 36px
  height: 36px
  & .path
    stroke: hsl(210, 70, 75)
    stroke-linecap: round
    animation: dash 1.5s ease-in-out infinite

  @keyframes rotate
    100%
      transform: rotate(360deg)
  @keyframes  dash
    0%
      stroke-dasharray: 1, 150
      stroke-dashoffset: 0
    50%
      stroke-dasharray: 90, 150
      stroke-dashoffset: -35
    100%
      stroke-dasharray: 90, 150
      stroke-dashoffset: -124

.claim-loading
  height: 150px
  width: 300px
  display: flex
  justify-content: center
  align-items: center
