@import '../../styles/vars.sass'

.project-requirements
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 20px
  &__item
    height: 30px
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 10px
    &:last-child
      margin-bottom: 0
    &-key
      display: flex
      align-items: center
      gap: 10px
      font-size: 16px
      line-height: 140%
      &._wait
        color: #F2C94C
      &._fail
        color: $danger
    &-value
      &._wait
        display: flex
        align-items: center
        &-text
          margin-right: 10px
          color: #F2C94C

@media (max-width: $tablet-width)
  .project-requirements
    &__item
      &-key
        font-size: 14px

@media (max-width: $mobile-width)
  .project-requirements
    margin-bottom: 32px
    &__title
      font-size: 13px
      line-height: 100%
      margin-bottom: 18px
    &__item-key
      font-size: 14px
