@import '../styles/vars.sass'

.project
  margin-bottom: 120px !important
  &__loader
    height: 600px
  &__title
    display: flex
    gap: 13px
    align-items: center
    font-weight: 600
    font-size: 36px
    line-height: 120%
    text-transform: uppercase
    margin-bottom: 20px
  &__logo
    width: 60px
    height: 60px
    background: #fff
    border-radius: 14px
    background-position: center
    background-repeat: no-repeat
  &__links
    display: flex
    flex-wrap: wrap
    gap: 10px
    margin-bottom: 50px
  &__timeline
    margin-bottom: 50px
  &__body
    display: flex
    gap: 18px
  &__info
    max-width: 430px
    min-width: 430px
  &__wizard
    width: 100%

@media (max-width: $small-desktop-width)
  .project
    margin-bottom: 80px !important
    &__loader
      height: 100vh
    &__body
      flex-direction: column
      gap: 40px
    &__info
      max-width: 100%
      min-width: 100%
      display: flex
      flex-direction: column-reverse

@media (max-width: $tablet-width)
  .project
    margin-bottom: 60px !important
    &__title
      font-size: 28px
      margin-bottom: 40px
    &__logo
      width: 45px
      height: 45px
    &__links,
    &__timeline
      margin-bottom: 40px

@media (max-width: $mobile-width)
  .project
    margin-bottom: 40px !important
    &__title
      text-transform: capitalize
      font-size: 24px
      margin-bottom: 20px
    &__logo
      width: 60px
      height: 60px
    &__timeline
      margin-bottom: 23px
