@import '../../styles/vars.sass'

.refund-policy
  padding: 36px
  display: block
  background: $dark
  border-radius: 40px
  margin-bottom: 18px
  &__row
    display: flex
    align-items: center
    gap: 30px
    margin-bottom: 36px
    &:first-child
      margin-bottom: 18px
    &:last-child
      margin-bottom: 0
  &__title
    font-weight: 700
    font-size: 24px
    line-height: 140%
    letter-spacing: -0.01em
  &__text
    line-height: 140%
    letter-spacing: -0.01em
    color: $text-light-secondary
    &-special
      font-weight: 600
  &__button
    max-width: fit-content
    padding: 0 15px

@media (max-width: $tablet-width)
  .refund-policy
    &__timer
      margin-bottom: 36px

@media (max-width: $mobile-width)
  .refund-policy
    border: 1px solid $outline
    border-radius: 20px
    margin-bottom: 28px
    padding: 14px
    padding-bottom: 18px
    &__row
      flex-direction: column-reverse
      margin-bottom: 20px
      gap: 12px
    &__timer
      margin-bottom: 18px
    &__text
      font-size: 14px
