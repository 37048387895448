@import "../../styles/vars"

.tab
  font-weight: 700
  font-size: 13px
  line-height: 140%
  text-transform: uppercase
  margin-bottom: 20px
  cursor: pointer
  &._selected
    color: $text-active
    padding-bottom: 6px
    border-bottom: 2px solid $text-active
  &__list
    display: flex
    align-items: flex-start
    gap: 18px
