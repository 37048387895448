.agree-modal
  display: flex
  max-width: 390px
  flex-direction: column
  &__title
    font-size: 24px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 32px
  &__buttons
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: 16px
    width: 340px
  &__button
    padding: 12px
    border-radius: 12px
    &._bg-green
      background: rgb(16, 170, 68)
    &._bg-red
      background: rgb(184, 62, 62)
