.private-claim-modal
  display: flex
  max-width: 390px
  flex-direction: column
  &__title
    font-size: 24px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 32px
  &__line
    margin-bottom: 8px
    display: flex
    align-items: baseline
    gap: 8px
    &-rounded-num
      color: rgb(187, 194, 202)
      height: 24px
      width: 24px
      border-radius: 10000px
      background: #283241
      display: flex
      justify-content: space-around
      align-items: center
  &__tiers
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: 8px
    margin-bottom: 24px
    @media (max-width: 787px)
      grid-template-columns: repeat(1, minmax(0, 1fr))
  &__tier
    padding: 16px
    background: rgb(187 194 202 / 0.06)
    border-radius: 12px
    &-title
      display: flex
      align-items: center
      margin-bottom: 8px
    &-badge
      display: inline-block
      margin-left: 8px
    &-ap
      display: flex
      align-items: center
      justify-content: space-between
      &-amount
        font-size: 12px
        line-height: 16px
        font-weight: 500

._mb-6
  margin-bottom: 24px
._gray-sub
  color: #92929E
._green
  color: rgb(16, 170, 68)
._red
  color: rgb(184, 62, 62)
