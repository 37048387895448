@import '../../../styles/vars.sass'

.ido-wizard
  background: $dark
  border-radius: 40px
  margin-bottom: 18px
  &__row
    padding: 36px
    display: flex
    justify-content: space-between
    gap: 40px
  &__col
    width: 50%
  &__timer
    margin-bottom: 38px
    &:last-child
      margin-bottom: 0

@media (max-width: $tablet-width)
  .ido-wizard
    &__row
      padding: 20px
      gap: 10px
    &__col
      width: 45%
    &__timer
      margin-bottom: 21px
