@import "../../styles/vars"

.tooltip
  z-index: 5
  position: relative
  &__container
    position: absolute
    padding: 12px
    opacity: 1
    border-radius: 10px
    background: $dark
    border: 1px solid $outline
    transition: all 0.3s
    &._hidden
      pointer-events: none
      opacity: 0
  &__text
    font-weight: 600
    font-size: 14px
    color: $text-light-secondary
    white-space: nowrap
    text-transform: capitalize
