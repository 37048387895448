@mixin font-face($font-family, $file-path, $weight: normal, $style: normal)
  @font-face
    font-family: $font-family
    font-weight: $weight
    font-style: $style
    src: url('#{$file-path}.eot')
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype')

@mixin text-h1()
  font-size: 72px
  font-weight: 500
  line-height: 1.25
  @media (max-width: 768px)
    font-size: 30px
    line-height: 1.125
@mixin text-h2()
  font-size: 48px
  font-weight: 500
  line-height: 1.25
  @media (max-width: 768px)
    font-size: 24px
@mixin text-h3()
  font-size: 30px
  font-weight: 500
  line-height: 1.25
  @media (max-width: 768px)
    font-size: 20px
@mixin text-h4()
  font-size: 24px
  font-weight: 500
  line-height: 1.25
@mixin text-h5()
  font-size: 12px
  font-weight: 500
  line-height: 1.35
  text-transform: uppercase
  @media (max-width: 768px)
    font-size: 8px
@mixin text-h6()
  font-size: 10px
  font-weight: 500
  line-height: 1.4
  text-transform: uppercase

@mixin text-p1()
  font-size: 20px
  line-height: 1.4
  @media (max-width: 768px)
    font-size: 16px
    line-height: 1.5
@mixin text-p2()
  font-size: 16px
  line-height: 1.5
  @media (max-width: 768px)
    font-size: 14px
    line-height: 1.45
@mixin text-p3()
  font-size: 14px
  line-height: 1.45
  @media (max-width: 768px)
    font-size: 12px
    line-height: 1.33

@mixin text-grad-r-blue-cyan()
  background-image: linear-gradient(to right, var(--tw-gradient-stops))
  --tw-gradient-from: #0098FF
  --tw-gradient-to: rgb(0 152 255 / 0)
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
  --tw-gradient-to: #01C6FF
  -webkit-background-clip: text
  background-clip: text
  color: transparent

@mixin text-grad-accent()
  background-image: linear-gradient(to top, var(--tw-gradient-stops))
  --tw-gradient-from: #0098FF
  --tw-gradient-to: rgb(0 152 255 / 0)
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
  --tw-gradient-to: #01C6FF
  -webkit-background-clip: text
  background-clip: text
  color: transparent
@mixin text-grad-base()
  background-image: linear-gradient(to top, var(--tw-gradient-stops))
  --tw-gradient-from: rgba(255,255,255,.5)
  --tw-gradient-to: rgb(255 255 255 / 0)
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
  --tw-gradient-to: #EEF9FC
  -webkit-background-clip: text
  background-clip: text
  color: transparent
@mixin card-bg-grad()
  background: linear-gradient(180deg, rgba(15, 28, 43, 0) 0%, rgba(87, 165, 255, 0.1) 100%)
@mixin base-bg-grad()
  background: linear-gradient(180deg, #060d18, #0e1c33)
@mixin text-grad-form()
  background: linear-gradient(73.6deg, rgba(39, 25, 70, 0.5) 1.02%, rgba(12, 34, 66, 0.5) 95.39%)
@mixin top-grad ()
  background-image: radial-gradient(47.89% 100.21% at 50.75% 100%,rgba(88, 10, 255, 0.28) 0,rgba(88, 10, 255, 0) 100%)
