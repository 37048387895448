@import "../styles/vars"

.footer
  width: 100%
  &__inner
    margin: 0 auto
    max-width: 1320px
    padding: 50px 0px 20px 0px
    display: flex
    align-items: center
    justify-content: space-between
  &__block
    width: 100%
    display: flex
  &__column
    display: flex
    flex-direction: column
    gap: 15px
    &-group
      display: flex
      align-items: center
      justify-content: space-around
      &:first-child
        width: 55%
      &:last-child
        width: 45%
  &__menu
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    &-item
      min-height: 40px // for translation
      position: relative
      padding: 4px
      display: flex
      align-items: center
      font-size: 16px
      line-height: 100%
      letter-spacing: 0.02em
      transition: color 0.3s
      & svg *
        transition: all 0.3s
      &:hover
        color: $active-lighter
        & svg *
          stroke: $active-lighter
    &-icon
      position: absolute
      top: 50%
      left: -4px
      transform: translate(-100%, -50%)
  &__logo
    &-icon
      width: 239px
  &__social
    display: flex
    gap: 6px
    &-item
      width: 28px
      height: 28px
      &-icon
        & *
          transition: all 0.3s
        &:hover *
          fill: $active-lighter
        &:hover circle // for dot in tg icon
          fill: #FF6F43
  &__email
    transition: all 0.3s
    &:hover
      color: $active-lighter
  &__copyright
    font-size: 12px
    color: #FFFFFF
    opacity: 0.6
    margin-left: 40px
    &-link
      transition: all 0.3s
      &:hover
        color: $active-lighter

@media (max-width: $small-desktop-width)
  .footer
    &__inner
      max-width: 855px
      align-items: start
    &__menu
      margin-left: 40px
    &__column-group
      flex-direction: column
      align-items: start
      gap: 15px
      &:last-child
        width: 45%

@media (max-width: $tablet-width)
  .footer
    &__inner
      max-width: 640px
    &__menu-item
      min-height: 34px
      font-size: 13px
    &__logo-icon
      width: 143px
    &__email
      font-size: 13px
    &__copyright
      margin-left: 10px

@media (max-width: $mobile-width)
  .footer
    &__inner
      flex-direction: column-reverse
      align-items: center
      padding: 20px 40px 80px
      max-width: 100%
      gap: 40px
      & > .footer__column:first-child
        flex-direction: row
        align-items: center
        gap: 0px
    &__block
      flex-direction: column
      align-items: center
      gap: 40px
    &__menu
      max-width: 300px
      margin-left: 0
      &-icon
        display: none
    &__social
      margin-bottom: 13px
    &__email
      font-size: 16px
