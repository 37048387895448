@import '../../../styles/vars.sass'

.application-status
  border-radius: 18px
  background-color: #1B243B
  font-weight: 500
  font-family: "Inter"
  color: $light
  font-size: 16px
  line-height: 140%
  letter-spacing: 0.02em
  width: 100%
  &._rejected
    margin-bottom: 18px
  &__inner
    display: flex
    padding: 18px 26px
  &__text
    &_support
      color: #F2C94C
      font-size: 14px
      margin-right: 10px
  &__icon
    width: 22px
    height: 22px
    margin-right: 10px
    &._animated
      animation: spinner 3s infinite linear

@media (max-width: $mobile-width)
  .application-status
    font-size: 14px
    line-height: 140%
