@import '../styles/vars.sass'

.project-tag
  width: fit-content
  margin-right: 18px
  border-radius: 8px
  &:last-child
    margin-right: 0px
  &__value
    padding: 5px 10px
    text-transform: uppercase
    font-weight: 700
    font-size: 14px
    color: #FFFFFF
    line-height: 100%
  &._upcoming,
  &._live,
  &._expanded
    background-color: #5D04ED
  &._66,
  &._65,
  &._okc
    background-color: #000000
  &._52,
  &._53,
  &._coinex,
  &._csc
    background-color: #17E5AA
  &._137,
  &._80001,
  &._polygon
    background-color: #8247e5
  &._56,
  &._97,
  &._bsc
    background-color: #F3BA2F
  &._1,
  &._5
    background-color: #627fea
  &._closed,
  &._refund,
  &._postponed,
  &._refunded
    background-color: #353444
  &._solana
    background: url(../../assets/img/solana-bg.png)
    background-size: cover
@media (max-width: $tablet-width)
  .project-tag
    margin-right: 6px
    &__value
      padding: 4px 8px
      font-size: 12px

@media (max-width: $mobile-width)
  .project-tag
    margin-right: 4px
