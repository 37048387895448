@import '../../../styles/mixins'
@import '../../../styles/vars'

.account-block
  &__content
    transition: opacity 1.3s ease, transform 1.3s ease
    @media screen and ( max-width: 1024px )
      width: 100%
      display: flex
      justify-content: center
      @media screen and ( max-width: 768px )
        max-width: 480px
  &__title
    +text-h3()
    color: $t-white
    margin-bottom: 20px
    @media screen and ( max-width: 960px )
      text-align: center
  &__grid
    display: grid
    grid-template-columns: repeat(2 , 1fr)
    gap: 28px
    @media screen and ( max-width: 1320px )
      grid-template-columns: repeat(1 , 1fr)
      @media screen and ( max-width: 1024px )
        width: 100%
        @media screen and ( max-width: 960px )
          grid-template-columns: repeat(1 , 1fr)
  &__info
    display: flex
    flex-direction: column
    gap: 28px
