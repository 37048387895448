@import '../../../styles/mixins'
@import '../../../styles/vars'

.amount-input
  padding: 16px
  background: rgba(5, 13, 23, 0.5)
  border-radius: 16px
  &__info-badge
    cursor: pointer
  &__info
    display: flex
    align-items: center
    margin-bottom: 8px
    &-balance
      +text-p3()
      color: $t-gray
      margin-right: 8px
    &-badge
      text-transform: uppercase
      margin-right: 8px
  &__input
    display: flex
    align-items: center
    &-img
      margin-right: 8px
    &-text ,>input
      +text-p2()
      color: $t-white
      margin-right: 8px
      font-weight: 500
