@import '../../styles/vars.sass'

.pagination
  display: flex
  justify-content: center
  gap: 8px
  max-width: 100%
  &__page-link,
  &__arrow-link,
  &__break-link
    user-select: none
    cursor: pointer
    border-radius: 5px
    border: 1px solid $outline
    display: flex
    align-items: center
    justify-content: center
    height: 28px
    width: 28px
  &__page-link
    &._active
      border-color: $active-lighter
      color: $active-lighter
  &__arrow-link
    padding-bottom: 2px
    &._disabled
      opacity: 0.5
  &__break-link
    padding-bottom: 30%
    color: $text-dark-secondary
