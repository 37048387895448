@import './../../../styles/mixins'
@import '../../../styles/vars'

.kv-list
  display: flex
  flex-direction: column
  &__content
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 16px
    &:last-child
      margin-bottom: 0
  &__title
    text-transform: uppercase
    +text-p3()
    color: $t-gray
    @media (max-width:961px)
      max-width: 240px
  &__info
    text-transform: uppercase
    +text-p3()
    color: $t-white
    &._disabled
      opacity: .5
