@import "../../styles/vars.sass"

.wallet-mobile
  width: 100%
  pointer-events: none
  position: absolute
  border-radius: 18px
  border: 2px solid $outline
  top: 45px
  opacity: 0
  background: $dark
  overflow: hidden
  z-index: -100
  transition: all 0.3s
  &._opened
    pointer-events: auto
    opacity: 1
    z-index: 1
    top: 60px
  &__item
    display: flex
    align-items: center
    gap: 10px
    padding: 12px 16px
    cursor: pointer
    transition: all 0.25s
    &:not(&:last-child)
      border-bottom: 2px solid $outline
    &:hover
      background: $dark-hover
  &__items
    display: flex
    align-items: center
    gap: 10px
    padding: 12px 16px
    cursor: pointer
    transition: all 0.25s
    &:not(&:last-child)
      border-bottom: 2px solid $outline
    &:hover
      background: $dark-hover
  &__img
    width: 24px
    height: 24px
    & > svg > path
      fill: $text-primary
  &__title
    font-weight: 500
    line-height: 120%
    letter-spacing: 0.02em
    &-special
      color: $danger
      font-weight: 500

// @media (max-width: $small-desktop-width)
//   .user-menu
//     position: sticky
//     border: none
//     background-color: transparent
//     &__img
//       & > svg > path
//         fill: $text-light-secondary
//     &__item
//       padding: 0px
//       padding-left: 16px
//       margin-bottom: 10px
//       &:hover
//         background-color: transparent
//       &:not(&:last-child)
//         border-bottom: none
//     &__title
//       color: $text-light-secondary
