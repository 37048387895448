@import "../../styles/vars"

.networks
  &__title
    font-weight: 500
    font-size: 40px
    margin-bottom: 36px
    color: $light
    line-height: 120%
    text-align: left
  &__buy
    align-items: center
    display: flex
    gap: 82px
  &__button
    margin-bottom: 40px
    width: 180px
  &__left
    display: flex
    justify-content: center
  &__image
    position: relative
    width: 250px
    height: 250px
    &-line
      position: absolute
      width: 82%
      object-fit: contain
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      animation: rotation 5s linear infinite
      &-img
        object-fit: contain
    &-ggr
      width: 35%
      object-fit: contain
      position: absolute
      transform: translate(50%, -50%)
      right: 0
      top: 50%
      animation: ggr-rotation 5s linear infinite

@media (max-width: $tablet-width)
  .networks
    &__title
      font-size: 24px
      margin-bottom: 40px
    &__button
      margin-bottom: 40px
      &-btn
        width: 150px
        height: 40px

@media (max-width: $mobile-width)
  .networks
    margin: 0 -16px
    &__buy
      flex-direction: column
      gap: 20px
    &__button
      width: 100%
      margin-bottom: 0px
    &__title
      text-align: center
      margin-bottom: 30px
      font-size: 26px
    &__link
      min-width: 100%
      width: 100%
      text-align: left
    &__left
      margin-bottom: 10px
      align-items: center
      text-align: center
    &__image
      transform: none
      width: 200px
      height: 200px
      &-round
        width: 100%
        height: 100%
      &-line
        width: 170px
        height: 170px
        &-img
          width: 100%
          height: 100%
      &-ggr
        width: 50px
        height: 50px
        right: 5%

@keyframes rotation
  0%
    transform: translate(-50%, -50%) rotate(0deg)
  100%
    transform: translate(-50%, -50%) rotate(360deg)

@keyframes ggr-rotation
  0%
    transform: translate(50%, -50%) rotate(360deg)

  100%
    transform: translate(50%, -50%) rotate(0deg)
