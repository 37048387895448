@import '../../../styles/vars.sass'

.check-network
  max-width: 500px
  &__inner
    border-radius: 18px
    background: rgba(242, 201, 76, 0.18)
    padding: 16px
    display: flex
    gap: 10px
    margin-bottom: 10px
  &__icon
    width: 22px
    height: 22px
  &__text
    color: $warning
    font-weight: 500
    line-height: 150%
    &._white
      color: $white
  &__actions
    display: flex
    gap: 10px
  &__chain-selector
    z-index: 6

@media (max-width: $mobile-width)
  .check-network
    &__actions
      flex-direction: column
    &__chain-selector
      width: 100%
      &-list
        width: 100%
