@import '../../../styles/mixins'
@import '../../../styles/vars'

.ton-card
  display: flex
  justify-content: center
  align-items: flex-end
  background-position: center
  background-size: cover
  border: 1px solid $t-stroke
  border-radius: 24px
  overflow: hidden
  position: relative
  &:hover
    .ton-card__img
      transform: scale(1.1)
  &._square
    position: relative
    &::before
      content: ""
      display: block
      width: 100%
      padding-top: 100%
  &._bottom-center
    justify-content: center
    align-items: flex-end
  &._top-center
    justify-content: center
    align-items: flex-start
  &._center-center
    justify-content: center
    align-items: center
  &._center-left
    justify-content: flex-start
    align-items: center
  &._no-bg
    +card-bg-grad()
  &._pointer
    cursor: pointer
  &__img
    position: absolute
    transition-property: all
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
    transition-duration: 150ms
    object-fit: cover
    background-size: cover
    width: 100%
    height: 100%
    display: block
    z-index: 0
    top: 0px
    right: 0px
    bottom: 0px
    left: 0px
  &__content
    width: 100%
    z-index: 8
    &._soon
      filter: blur(10px)
    &._square-bc
      position: absolute
      bottom: 0
  &__soon
    position: absolute
    height: 100%
    width: 100%
    z-index: 9
    &-text
      position: absolute
      top: 25px
      left: 25px
      +text-h4()
      font-weight: 600
      color: $t-white
      display: flex
      gap: 16px
      &-sub
        display: flex
        align-items: center
        +text-p2()
        font-weight: 600
        text-transform: uppercase
        padding: 0 8px
        background: $t-blue
        border-radius: 8px
._base
  padding: 40px
  @media (max-width: 768px)
    padding: 24px
._sm
  padding: 16px
._square-c
  position: relative
  height: 100%

._relative
  position: relative
.tets
  position: absolute
  top: 20px
  left: 20px
