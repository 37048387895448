@import '../../styles/vars.sass'

.project-info
  background: $dark
  border-radius: 40px
  margin-bottom: 18px
  &__protection
    padding: 18px 36px 18px 36px
    border-bottom: 1px solid $outline
    margin: 0 auto
    &-type
      display: flex
      justify-content: center
      .advanced
        color: #D3B055
      p
        align-self: center
        margin-left: 4px
  &__list
    padding: 36px 36px 18px 36px
    border-bottom: 1px solid $outline
    &-item
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 10px
      &:last-child
        margin-bottom: 0
    &-key
      font-size: 16px
      line-height: 140%
      color: $text-light-secondary
    &-value
      text-align: right
      display: flex
      align-items: center
      gap: 6px
      font-weight: 700
      font-size: 16px
      line-height: 140%
  &__bottom
    padding: 18px 36px 36px 36px
  &__schedule
    display: flex
    align-items: center
    gap: 10px
    font-size: 16px
    line-height: 140%
    margin-bottom: 20px
    cursor: pointer

@media (max-width: $tablet-width)
  .project-info
    &__list,
    &__bottom
      padding: 20px

@media (max-width: $mobile-width)
  .project-info
    border: 1px solid $outline
    border-radius: 20px
    &__list
      padding: 14px 14px 18px 14px
      &-key
        font-weight: 600
        font-size: 14px
      &-value
        font-size: 14px
    &__bottom
      padding: 14px 14px 18px 14px
