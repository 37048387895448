@import '../../styles/vars.sass'

.project-lose
  &__item
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
  &__icon
    margin-bottom: 18px
    display: block
  &__title
    font-weight: 600
    font-size: 20px
    line-height: 150%
    text-align: center

@media (max-width: $mobile-width)
  .project-lose
    margin-bottom: 28px
