@import '../../styles/vars.sass'

.description-wizard
  width: 100%
  padding: 36px
  background: $dark
  border-radius: 40px
  margin-bottom: 18px
  &__back
    margin-right: 7px
    & path
      &:first-child
        fill: $text-primary
      &:last-child
        stroke: $text-primary
  &__head
    display: flex
    flex-direction: column
    justify-content: space-between
  &__title,
  &__tab
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 20px
  &__tab
    &._pointer
      cursor: pointer
  &__title
    &._pointer
      cursor: pointer
  &__tabs
    display: flex
    align-items: flex-start
    gap: 18px
  &__tab
    &._selected
      color: $text-active
      padding-bottom: 6px
      border-bottom: 2px solid $text-active
  &__body
    font-size: 16px
    line-height: 150%
    color: $text-light-secondary
    &:not(:last-child)
      margin-bottom: 36px
    & p:not(:last-child)
      margin-bottom: 1em
    & a
      color: $active
    & strong
      display: block
      position: relative
      width: 100%
      height: 100%
    & p:last-child > strong::after
      content: ""
      display: block
      padding-top: 56.25%
    & iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

@media (max-width: $small-desktop-width)
  .description-wizard
    &__body
      &:not(:last-child)
        margin-bottom: 18px

@media (max-width: $tablet-width)
  .description-wizard
    padding: 20px
    &__head
      display: block
      flex-wrap: wrap

@media (max-width: $mobile-width)
  .description-wizard
    border: 1px solid $outline
    border-radius: 20px
    margin-bottom: 28px
    padding: 14px
    &__title
      font-size: 14px
      line-height: 100%
      margin-bottom: 14px
    &__body
      font-size: 14px
