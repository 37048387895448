@import "../styles/vars"

.referral-program
  &__title
    margin-bottom: 80px !important
  &__background
    position: absolute
    top: 105px
    pointer-events: none
    z-index: -2
    &.left
      width: 285px
      left: -45px
      top: 180px
    &.right
      right: 0
      top: 210px

@media (max-width: $small-desktop-width)
  .referral-program
    &__title
      margin-bottom: 60px !important

@media (max-width: $tablet-width)
  .referral-program
    &__background
      top: 105px
      pointer-events: none
      z-index: -2
      &.left
        left: -110px
        width: 400px
        height: 400px
      &.right
        width: 300px
        height: 300px
        right: -78px

@media (max-width: $mobile-width)
  .referral-program
    &__title
      margin-bottom: 40px !important
