@import "../../styles/vars"

.referrers-list
  max-width: 762px
  margin: 0 auto
  background: $dark
  border: 1px solid $outline
  border-radius: 40px
  overflow: hidden
  &__loader
    height: 150px
  &__title
    text-transform: uppercase
    font-weight: 700
    font-size: 14px
    color: $secondary
    padding: 36px
    padding-bottom: 18px

@media (max-width: $tablet-width)
  .referrers-list
    border-radius: 20px
    &__title
      padding: 18px
      padding-bottom: 9px

@media (max-width: $mobile-width)
  .referrers-list
    &__title
      padding: 14px
      padding-bottom: 7px
