@import "../../styles/vars"

.switcher
  background: $dark
  border-radius: 20px
  width: fit-content
  &__items
    display: flex
    align-items: center
  &__item
    position: relative
    cursor: pointer
    border-radius: 20px
    text-transform: capitalize
    padding: 14px 28px
    font-weight: 500
    font-size: 20px
    line-height: 100%
    color: $secondary
    transition: color 0.3s
    &:hover
      color: $active
    &-text
      position: relative
      text-align: center
      z-index: 2
    &._active
      color: $text-primary
  &__active
    border-radius: 20px
    position: absolute
    z-index: 1
    background: #014DC4
    color: $text-primary
  &__new
    border-radius: 20px
    font-size: 9px
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    color: #fff
    background: #ff6f43
    width: 36px
    height: 17px
    top: -7px
    right: -6px
    z-index: 1
  &__soon
    border-radius: 20px
    font-size: 9px
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    color: #fff
    background: #ff6f43
    width: 36px
    height: 17px
    top: -10px
    right: -16px
    z-index: 1

@media (max-width: $tablet-width)
  .switcher
    &__item
      padding: 7px 10px
      font-size: 14px

@media (max-width: $mobile-width)
  .switcher
    background: transparent
    &__items
      gap: 8px
      flex-wrap: wrap
      justify-content: center
    &__item
      background: $dark
      min-width: 60px
      padding: 8px 10px
      border: 1px solid $secondary-darker
      transition: background 0.3s
      &._active
        background: #014DC4
    &__active
      display: none
