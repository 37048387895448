@import '../../styles/vars'

.circle
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  &__inner
    display: inline-block
    position: relative
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
  &__item
    transform-origin: 1px 1px
    animation: fadeOut 1.2s linear infinite
    &:after
      content: " "
      display: block
      position: absolute
      top: 0px
      left: 0px
      width: 8px
      height: 2px
      margin-left: 7px
      border-radius: 20%
      background: $light
    &._darker:after
      background: $secondary
    &:nth-child(1)
      transform: rotate(0deg)
      animation-delay: -1.1s
    &:nth-child(2)
      transform: rotate(30deg)
      animation-delay: -1s
    &:nth-child(3)
      transform: rotate(60deg)
      animation-delay: -0.9s
    &:nth-child(4)
      transform: rotate(90deg)
      animation-delay: -0.8s
    &:nth-child(5)
      transform: rotate(120deg)
      animation-delay: -0.7s
    &:nth-child(6)
      transform: rotate(150deg)
      animation-delay: -0.6s
    &:nth-child(7)
      transform: rotate(180deg)
      animation-delay: -0.5s
    &:nth-child(8)
      transform: rotate(210deg)
      animation-delay: -0.4s
    &:nth-child(9)
      transform: rotate(240deg)
      animation-delay: -0.3s
    &:nth-child(10)
      transform: rotate(270deg)
      animation-delay: -0.2s
    &:nth-child(11)
      transform: rotate(300deg)
      animation-delay: -0.1s
    &:nth-child(12)
      transform: rotate(330deg)
      animation-delay: 0s

@keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0
