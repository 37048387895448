@import "../../../styles/vars"

.refund-confirm-modal
  &__title
    font-weight: 600
    font-size: 36px
    line-height: 140%
    margin-bottom: 24px
    text-align: center
  &__desc
    font-size: 18px
    line-height: 120%
    margin-bottom: 36px
    text-align: center
    & span
      color: $danger
  &__buttons
    display: flex
    gap: 18px
  &__button
    text-transform: capitalize
