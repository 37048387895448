@import '../../../styles/vars.sass'

.project-ended
  height: 100%
  align-items: center
  display: flex
  flex-direction: column
  margin-bottom: 24px
  &__loader
    height: 300px
  &__text
    font-weight: 600
    text-align: center
    font-size: 20px
    width: 60%
    line-height: 150%
    &._flex
      display: flex
      align-items: center
      justify-content: center
  &__token-icon
    width: 20px
    height: 20px
    margin: 0 5px
  &__img
    width: 200px
    height: 200px
    margin-bottom: 18px

@media (max-width: $tablet-width)
  .project-ended
    margin-bottom: 16px

@media (max-width: $mobile-width)
  .project-ended
    margin-bottom: 20px
    &__connect
      padding: 20px
    &__text
      font-size: 14px
    &__token-icon
      width: 16px
      height: 16px
    &__img
      width: 150px
      height: 150px
