@import "../../styles/vars"

.selector
  position: relative
  &__item
    height: 100%
    position: relative
    border: 2px solid $outline
    padding: 16px
    border-radius: 18px
    display: flex
    gap: 5px
    align-items: center
    font-weight: 500
    letter-spacing: 0.02em
    text-transform: capitalize
    transition: all 0.25s
    white-space: nowrap
    &:hover
      background: $dark-hover
    &._open
      border-radius: 18px 18px 0px 0px
    &._disabled
      &:hover
        background: transparent
    &._selected > div::after
      content: ''
      position: absolute
      border-radius: 50%
      left: -1px // border / 2
      top: -1px // border / 2
      width: calc(100% + 2px) // border
      height: calc(100% + 2px) // border
      border: 2px solid $active-lighter
    &-icon
      position: relative
      width: 16px
      height: 16px
      border-radius: 50%
      background-repeat: no-repeat
      background-size: contain
      background-position: 50%
  &__popup
    pointer-events: none
    opacity: 0
    background: $dark
    position: absolute
    overflow: hidden
    top: 0
    left: 0
    border: 2px solid $outline
    border-radius: 18px
    transition: opacity 0.3s
    z-index: 1
    &._open
      pointer-events: auto
      opacity: 1
    & > div
      border-radius: 0
      border: none
      border-bottom: 2px solid $outline
      // &:first-child > div::after
      //   content: ''
      //   position: absolute
      //   border-radius: 50%
      //   left: -1px // border / 2
      //   top: -1px // border / 2
      //   width: calc(100% + 2px) // border
      //   height: calc(100% + 2px) // border
      //   border: 2px solid $active-lighter
      &:last-child
        border-bottom: none
  &__arrow
    pointer-events: none
    position: absolute
    z-index: 2
    right: 18px
    top: 50%
    transform: translateY(-50%)
    transition: transform 0.3s
    &._open
      transform: translateY(-50%) rotate(180deg)
    &._hidden
      display: none

@media (max-width: $tablet-width)
  .selector
    &__item
      padding: 12px 16px

@media (max-width: $mobile-width)
  .selector
    &__item
      &._clipped
        padding: 8px 12px
        & > .selector__item-icon
          width: 24px
          height: 24px
