@import '../../../styles/vars.sass'

.project-whitelist
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 18px
  &__button
    width: 100%
