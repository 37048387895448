.agree-modal-withdraw
  display: flex
  width: 392px
  flex-direction: column
  padding: 24px
  &__title
    font-size: 24px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 32px
    color: #EEF9FC
    margin-bottom: 28px
  &__text
    font-weight: 500
    font-size: 16px
    line-height: 24px
    letter-spacing: -0.01em
    color: #EEF9FC
    margin-bottom: 16px
  &__sub-text
    font-weight: 500
    font-size: 12px
    line-height: 16px
    letter-spacing: 0.03em
    text-transform: uppercase
    margin-bottom: 4px
  &__sum
    font-weight: 700
    font-size: 24px
    line-height: 32px
    color: #FFFFFF
    margin-bottom: 24px
  &__buttons
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: 16px

  &__button
    padding: 12px
    border-radius: 12px
    &._bg-green
      border: 1px solid rgb(16, 170, 68)
      color: rgb(16, 170, 68)
      transition: all .4s
      &:hover
        background: rgb(16, 170, 68)
        color: #FFFFFF
    &._bg-red
      border: 1px solid rgb(184, 62, 62)
      color: rgb(184, 62, 62)
      transition: all .4s
      &:hover
        background: rgb(184, 62, 62)
        color: #FFFFFF
