@import '../../styles/vars'

.project-list
  width: 100%
  &__title
    font-weight: 500
    font-size: 24px
    line-height: 100%
    margin-bottom: 40px
  &__switcher
    display: flex
    margin-bottom: 20px
  &__loader
    height: 400px
  &__swiper
    padding-top: 20px
    padding-bottom: 20px
  &__items
    padding-top: 20px
    display: flex
    flex-wrap: wrap
    gap: 20px
    width: 100%
  &__empty
    font-weight: 500
    height: 250px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 20px
    color: $text-light-secondary
    font-size: 20px
    text-align: center
  &__item
    width: 420px

@media (max-width: $small-desktop-width)
  .project-list
    & > .page__inner:first-child // обёртка над &__title
      max-width: 855px // 2 карточки + gap
    &__items
      width: 855px
      margin: auto
      gap: 15px

@media (max-width: $tablet-width)
  .project-list
    & > .page__inner:first-child // обёртка над &__title
      max-width: 640px
    & > .page__inner:last-child // обёртка над &__items
      max-width: 100%
      padding: 0px
    &__title
      font-size: 18px
      margin-bottom: 20px
    &__switcher
      margin-bottom: 0px
    &__items
      flex-wrap: nowrap
      gap: 9px
      width: 100%
    &__item
      width: 315px

@media (max-width: $mobile-width)
  .project-list
    &__title
      font-size: 20px
      text-align: center
      margin-bottom: 28px
      &._no-margin
        margin-bottom: 0px
    &__switcher
      display: flex
      justify-content: center
      margin-bottom: 8px
    &__item
      width: 290px
