@import '../../styles/vars.sass'

.marketing-activity-content
  &__banner
    height: auto
    width: 100%
    margin-bottom: 30px
    border-radius: 20px
  &__title
    font-weight: 700
    font-size: 24px
    line-height: 150%
    color: $text-primary
    margin-bottom: 20px
  &__text
    line-height: 150%
    color: $text-light-secondary
  &__dates
    margin-bottom: 20px
    font-size: 14px
    line-height: 140%

@media (max-width: $small-desktop-width)
  .marketing-activity-content
    &__banner
      margin-bottom: 20px
    &__title
      font-size: 18px
      margin-bottom: 10px

@media (max-width: $tablet-width)
  .marketing-activity-content
    padding: 18px 20px
    &__tag
      padding: 7px 9px

@media (max-width: $mobile-width)
  .marketing-activity-content
    flex-direction: column
    padding: 14px 15px
    &__text
      font-size: 14px
      word-wrap: break-word
