@import './styles/vars'

.app
  position: relative
  z-index: 1
  background-image: $background-gradient
  background-repeat: no-repeat
  overflow-x: hidden
  &__loader
    width: 100vw
    height: 100vh
  &::after
    content: ""
    height: 100%
    width: 100%
    position: fixed
    left: 0
    top: 0
    will-change: transform
    background: url(../assets/img/bg.svg)
    background-size: cover
    z-index: -1
