@import "../../styles/vars"

.projects-coinex
  width: 100%
  &__title
    font-weight: 500
    font-size: 24px
    line-height: 100%
    color: $light
    margin-bottom: 40px
  &__block
    display: flex
    background: rgba(31, 45, 63, 0.3)
    border: 1px solid #212B44
    border-radius: 20px
    justify-content: center
    align-items: center
    gap: 36px
    padding: 142px 0
  &__img
    width: 77px
    height: 141px
  &__text
    font-weight: 400
    font-size: 16px
    line-height: 140%
    letter-spacing: -0.01em
    color: $text-dark
    width: fit-content

@media (max-width: $small-desktop-width)
  .projects-coinex
    &__block
      padding: 120px 0

@media (max-width: $tablet-width)
  .projects-coinex
    &__block
      padding: 143px 0
    &__img
      width: 71px
      height: 129px

@media (max-width: $mobile-width)
  .projects-coinex
    margin: 0 -16px
    &__title
      font-size: 26px
      margin-bottom: 50px
    &__block
      padding: 120px 40px
      flex-direction: column
      gap: 10px
      width: 106%
    &__text
      font-size: 14px
      text-align: center
