@import '../../../styles/vars.sass'

.project-swap
  &__loader
    height: 250px
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 18px
  &__win,
  &__wait,
  &__partial
    font-weight: 500
    font-size: 16px
    line-height: 150%
    margin-bottom: 18px
    display: flex
    align-items: center
    gap: 10px
  &__partial
    color: $text-light-secondary
  &__win
    color: $success
  &__wait
    color: $warning
  &__body
    position: relative
    margin-bottom: 18px
    background: rgba(27, 36, 59, 1)
    border: 1px solid #485166
    border-radius: 18px
    padding: 18px
    display: flex
    &-icon
      width: 30px
      height: 30px
      border-radius: 10px
    &-col
      width: 50%
      &:last-child
        *
          text-align: right
          justify-content: flex-end
    &-input
      max-width: 60%
      padding: 7.5px 0 7.5px 0
      border-radius: 8px
      font-size: 20px
      line-height: 140%
      &::placeholder
        font-weight: 600
        font-size: 20px
        line-height: 140%
    &-button
      text-transform: uppercase
      font-weight: 600
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.06em
      color: #00BFFF
      cursor: pointer
      margin-bottom: 8px
      &:last-child
        margin-bottom: 0
    &-arrow
      position: absolute
      top: 50%
      left: 50%
      margin-left: -7px
      margin-top: -3px
    &-title
      font-weight: 700
      font-size: 13px
      line-height: 100%
      text-transform: uppercase
      color: #485166
      margin-bottom: 18px
      &._blank
        opacity: 0
    &-value
      display: flex
      align-items: center
      gap: 10px
      font-weight: 600
      font-size: 20px
      line-height: 100%
      margin-bottom: 18px
      height: 30px
      &:last-child
        margin-bottom: 0
    &-desc
      font-size: 14px
      line-height: 100%
      text-align: right
      color: #92929E
    &-balance
      font-style: normal
      line-height: 18px
      &-title
        font-weight: 500
        color: #485166
      &-value
        font-weight: 600
        color: #FFFFFF
        white-space: nowrap
  &__desc
    font-size: 16px
    line-height: 150%
    color: $secondary
  &__buttons
    display: flex
    gap: 10px
    width: 100%
  &__error
    font-weight: 500
    margin-top: 5px
    margin-left: 10px
    color: #DE4343
    letter-spacing: 0.02em

@media (max-width: $mobile-width)
  .project-swap
    &__connect
      margin: 0 auto 18px
    &__network
      margin: 18px
    &__title,
    &__win,
    &__buttons,
    &__desc,
    &__wait,
    &__partial
      padding: 0px 14px
      margin-bottom: 18px
    &__title
      margin-top: 18px
    &__buttons
      gap: 14px
      flex-direction: column
    &__body
      border: none
      border-radius: 0px
      width: 100%
    &__error
      margin-left: 25px
      margin-top: 0px
      margin-bottom: 5px
