@import '../../../styles/vars.sass'

.project-manual
  border-radius: 30px
  padding: 36px
  background: $dark
  margin-bottom: 18px
  &__link
    width: fit-content
    display: flex
    align-items: center
    gap: 10px
    font-weight: 500
    line-height: 140%
    transition: all 0.3s
    &:not(:last-child)
      margin-bottom: 10px
    &:hover
      color: $active
      & svg
        transform: translate(3px, -3px)
      & *
        stroke: $active
    &-icon
      width: 10px
      height: 10px
      transition: all 0.3s
      & *
        transition: all 0.3s

@media (max-width: $small-desktop-width)
  .project-manual
    padding: 20px

@media (max-width: $mobile-width)
  .project-manual
    padding: 0
    margin-bottom: 20px
