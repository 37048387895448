@import "../../styles/vars"

.referrer-info
  background: #121B33
  border: 1px solid $outline
  border-radius: 23px
  margin-bottom: 18px
  &__loader
    height: 120px
  &__inner
    padding: 36px
    display: grid
    grid-template-columns: 3fr 4fr
  &__stats
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
  &__key
    font-weight: 700
    font-size: 14px
    text-transform: uppercase
    color: $secondary
    margin-bottom: 21px
  &__value
    letter-spacing: 0.02em
    color: $text-primary
    text-transform: uppercase
    &._green
      color: $success
    &._blank
      color: $text-dark-secondary
  &__link
    font-weight: 600
    color: $text-active
    padding-bottom: 2px
    border-bottom: 1px solid $text-active

@media (max-width: $small-desktop-width)
  .referrer-info
    &__inner
      grid-template-columns: 2fr 4fr
    &__key
      font-size: 13px
    &__value
      font-size: 14px

@media (max-width: $tablet-width)
  .referrer-info
    border-radius: 20px
    margin-bottom: 20px
    &__inner
      padding: 20px
      grid-template-rows: 1fr 1fr
      grid-template-columns: 1fr
      gap: 20px
    &__item
      gap: 14px

@media (max-width: $mobile-width)
  .referrer-info
    margin-bottom: 28px
    &__inner
      padding: 14px
      grid-template-rows: 1fr 4fr
      gap: 0
    &__stats
      grid-template-rows: 1fr 1fr 1fr 1fr
      grid-template-columns: 1fr
      gap: 8px
    &__item
      display: flex
      justify-content: space-between
    &__key,
    &__value
      font-size: 14px
      line-height: 140%
      font-weight: 600
      letter-spacing: 0.01em
      text-transform: capitalize
    &__key
      margin-bottom: 0
      color: $text-light-secondary
