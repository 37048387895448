@import "../../styles/vars"

.referrals-list
  overflow: hidden
  background: $dark
  border-radius: 40px
  border: 1px solid $outline
  &__loader
    height: 200px
  &__header
    padding: 38px
    padding-bottom: 0
    display: grid
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr
    color: $secondary
    text-transform: uppercase
    font-size: 14px
    font-weight: 700
    margin-bottom: 2px
  &__pagination
    padding: 18px
    padding-bottom: 36px

@media (max-width: $small-desktop-width)
  .referrals-list
    border-radius: 23px
    &__header
      font-size: 13px
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr
    &__pagination
      padding-bottom: 26px

@media (max-width: $tablet-width)
  .referrals-list
    &__header
      display: none
    &__pagination
      padding: 10px
      padding-bottom: 20px

@media (max-width: $mobile-width)
  .referrals-list
    border: none
    background: none
    overflow: visible
    &__pagination
      padding: 0
