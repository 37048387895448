@import '../../styles/vars.sass'

.marketing-activity-button
  border-radius: 10px
  padding: 0 20px
  display: flex
  transition: 0.3s
  align-items: center
  background-color: rgba(0, 191, 255, 0.2)
  box-shadow: 0px 0px 22px rgba(0, 191, 255, 0.2), inset 0px 0px 10px rgba(255, 255, 255, 0.05)
  border: 1px solid rgba(0, 191, 255, 0.1)
  &__list
    margin-bottom: 20px
    display: flex
    flex-wrap: wrap
    gap: 10px
  &__title
    color: $active-lighter
    font-weight: 600
    font-size: 14px
    text-transform: capitalize
    &._not-started,
    &._ended
      color: $text-light-secondary
  &._not-started,
  &._ended
    border: 1px solid $secondary-darker
    background-color: $secondary-darker
    color: $text-light-secondary
    box-shadow: none
  &._ended
    opacity: 0.6
  &._selected
    box-shadow: none
    border: 1px solid $active-lighter
    background-color: rgba(0, 191, 255, 0.2)
    box-shadow: 0px 0px 22px rgba(0, 191, 255, 0.2), inset 0px 0px 10px rgba(255, 255, 255, 0.05)
  &__img
    width: 34px
    height: 34px
