@import '../../styles/vars.sass'

.modal
  padding: 36px
  position: relative
  max-height: calc(100vh - 32px)
  &__overlay
    position: fixed
    inset: 0px
    &._opening
      opacity: 0
      animation: fadeIn ease-in 0.3s forwards
    &._closing
      opacity: 1
      animation: fadeOut ease-out 0.3s forwards
  &__close
    position: absolute
    right: 36px
    top: 36px
    width: 24px
    height: 24px
    background: no-repeat center url('../../../assets/img/close.svg')
    background-color: #0A132C
    background-size: cover
    cursor: pointer
  &__body
    max-height: calc(100vh - 96px) // 96 = padding-top + padding-bottom + .modal max-height
    overflow: auto
    &::-webkit-scrollbar
      width: 5px
    &::-webkit-scrollbar-track
      background: $background-color
      border-radius: 5px
    &::-webkit-scrollbar-thumb
      background: $text-dark-secondary
      border-radius: 5px
    &::-webkit-scrollbar-thumb:hover
      background: $text-light-secondary

@media (max-width: $tablet-width)
  .modal
    padding: 30px 54px
    &__body
      max-height: calc(100vh - 92px)
    &__close
      top: 30px
      right: 30px

@media (max-width: $mobile-width)
  .modal
    padding: 18px 14px
    &__body
      max-height: calc(100vh - 68px)
    &__close
      top: 18px
      right: 13px

@keyframes fadeIn
  100%
    opacity: 1

@keyframes fadeOut
  100%
    opacity: 0
