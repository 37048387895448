@import '../styles/vars'

.account
  & .page__bridge-widget
    display: flex
    flex-flow: row nowrap
    justify-content: center
  & .page__block
    margin-bottom: 72px
  &__info,
  &__info-group
    width: 100%
    display: flex
    gap: 74px
    &-title
      text-transform: uppercase
      font-weight: 700
      font-size: 13px
      line-height: 100%
      letter-spacing: -0.01em
      margin-bottom: 20px

@media (max-width: $small-desktop-width)
  .account
    & .page__block
      margin-bottom: 80px
    &__info
      display: block
      &-group
        gap: 40px
      &-title
        font-size: 13px
        line-height: 140%
        margin-bottom: 10px

@media (max-width: $mobile-width)
  .account
    & .page__block
      margin-bottom: 40px
    &__info
      &-group
        display: block
      &-title
        margin-bottom: 20px
        font-size: 14px
