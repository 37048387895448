.modal-ton
  position: relative
  height: auto
  widht: auto
  padding: 16px
  border-radius: 20px
  &__close
    position: absolute
    right: 16px
    top: 16px
    z-index: 999999
    cursor: pointer
    border-radius: 8px
    background: rgba(88, 101, 121, 0.24)
    padding: 4px
    &:hover
      background: rgba(88, 101, 121, 0.7)
