.project
  &__link
    border-radius: 56px
    display: flex
    justify-content: center
    align-items: center
    padding: 3px 18px
    background-color: #1B243B
    &-icon
      width: 24px
      height: 24px
    &-title
      margin-left: 4px
      color: #92929E
      font-weight: 500
      font-size: 14px
      line-height: 140%
      text-transform: capitalize
    &._telegram
      background-color: #25A2E0
    &._twitter
      background-color: #03A9F4
    &._discord
      background-color: #5662F6
    &._website
      background-color: #9C56F6
    &._bscscan
      background-color: #F0B90B
    &._medium
      background-color: black
    &._document
      background-color: #25A2E0
    &._instagram
      background: radial-gradient(135.43% 145% at 61.67% 100%, #FDC559 0%, #F42834 32.29%, #B927A4 74.49%, #6539D0 100%)
