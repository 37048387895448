@import './vars'
@import './mixins'

*
  box-sizing: border-box
  outline: none

body
  font-family: "Inter", sans-serif
  height: 100%

html
  height: 100%
  // &::-webkit-scrollbar
  //   width: 10px
  // &::-webkit-scrollbar-track
  //   background: $background-color
  // &::-webkit-scrollbar-thumb
  //   background: $text-dark-secondary
  // &::-webkit-scrollbar-thumb:hover
  //   background: $text-light-secondary

body,
#root
  color: $light
  letter-spacing: 0.01em

input
  font-family: Inter
  background: transparent
  &::placeholder
    color: $secondary

._p1
  +text-p1()
._p2
  +text-p2()
._p3
  +text-p3()

.page
  &__inner
    max-width: 1320px
    margin: 0 auto
  &__title
    width: 100%
    font-weight: 600
    font-size: 68px
    line-height: 120%
    text-align: center
    color: $light
    margin-bottom: 40px
  &__desc
    font-size: 18px
    line-height: 150%
    text-align: center
    color: $light
    max-width: 726px
    margin: 0 auto
    margin-bottom: 50px
  &__back
    font-weight: 500
    font-size: 14px
    line-height: 100%
    color: $secondary
    transition: all .3s ease
    cursor: pointer
    margin-bottom: 50px
    display: inline-flex
    align-items: baseline
    gap: 8px
    & svg path
      transition: all 0.3s ease
      &:first-child
        fill: $secondary
      &:last-child
        stroke: $secondary
    &:hover
      color: $active
      & svg path
        &:first-child
          fill: $active
        &:last-child
          stroke: $active
  &__block
    display: flex
    align-items: center
    justify-content: center
    gap: 18px
    margin-bottom: 160px

._g-text-active-lighter
  color: $active-lighter

@keyframes rotate-anim
  100%
    transform: rotate(-360deg)

._g-rotate-anim
  animation: rotate-anim 3s infinite linear
._g-lowercase
  text-transform: lowercase

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield

@media (max-width: $small-desktop-width)
  .page
    &__inner
      max-width: 855px

@media (max-width: $tablet-width)
  .page
    &__inner
      max-width: 640px
    &__title
      font-size: 42px
    &__desc
      font-size: 14px
      margin-bottom: 40px
    &__block
      margin-bottom: 80px
    &__back
      margin-bottom: 40px

@media (max-width: $mobile-width)
  .page
    &__inner
      padding: 0px 16px
    &__title
      font-size: 34px
      text-align: left
    &__desc
      text-align: left
    &__block
      margin-bottom: 100px
    &__back
      margin-bottom: 28px

@media (max-width: 360px)
  .page
    &__title
      font-size: 31px

.bg-bg_grad_accent
  background: linear-gradient(84.84deg,rgba(54, 82, 229, 0.8) 0.79%,rgba(0, 152, 255, 0.8) 48.04%,rgba(1, 198, 255, 0.8) 120.05%)
.bg-bg_grad_accent_revers
  background: linear-gradient(84.84deg,rgba(1, 198, 255, 0.8) 0.79%,rgba(0, 152, 255, 0.8) 48.04%,rgba(54, 82, 229, 0.8) 120.05%)
.bg-bg_grad_accent_alt
  background: linear-gradient(269.03deg, #9baefb 2.6%, #627eea 103.89%)
.bg-bg_grad_accent_alt_active
  background: linear-gradient(87.94deg, #9baefb -2.13%, #a3b6ff 101.44%)
.top-grad
  background-image: radial-gradient(47.89% 100.21% at 50.75% 100%,rgba(88, 10, 255, 0.28) 0,rgba(88, 10, 255, 0) 100%)

.grad-top-form
  background: linear-gradient(180deg, #050d17 0%, #0e1f31 100%)
  background-color: #050d17

.card-bg-grad
  background: linear-gradient(180deg, rgba(15, 28, 43, 0) 0%, rgba(87, 165, 255, 0.1) 100%)
.base-bg-grad
  background: linear-gradient(180deg, #060d18, #0e1c33)
