@import "../../styles/vars"

.opportunities
  &__block
    display: flex
    align-items: center
    justify-content: center
    gap: 18px
    margin-bottom: 100px
  &__content
    display: flex
    gap: 33px
  &__title
    text-align: center
    margin-bottom: 40px
    color: $light
    font-weight: 500
    font-size: 40px
    line-height: 125%
    letter-spacing: -0.04em
  &__text
    font-weight: 400
    font-size: 16px
    line-height: 145%
    letter-spacing: -0.04em
    color: $white
  &__item
    background: #1F2D3F
    border-radius: 25px
    padding: 36px
    width: 316px
    font-weight: 500
    font-size: 18px
    line-height: 145%
    letter-spacing: -0.04em
    color: $white
    transition: 0.3s
    &-num
      margin-bottom: 18px
      width: 44px
      height: 44px
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      color: rgba(255, 255, 255, 0.4)
      border: 1px solid rgba(255, 255, 255, 0.4)
      font-size: 24px
      font-weight: 400
      pointer-events: none
      transition: 0.3s
    &:hover
      transform: scale(1.05)
      background: #0096FF
      box-shadow: 0px 4px 100px rgba(0, 150, 255, 0.5)
      & > .opportunities__item-num
        color: white
        background: rgba(255, 255, 255, 0.4)
        border-color: rgba(255, 255, 255, 0.4)
        border: none

@media (max-width: $small-desktop-width)
  .opportunities
    &__item
      width: 266px

@media (max-width: $tablet-width)
  .opportunities
    margin: 0 -16px
    &__item
      width: 100%
    &__title
      font-size: 26px
      margin-bottom: 50px

@media (max-width: $mobile-width)
  .opportunities
    margin: 0 -16px
