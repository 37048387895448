@import '../../styles/vars'

.rocket
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  &__inner
    height: 100px
    width: 100px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    animation: moveParticles 6s linear infinite
    background: linear-gradient(90deg, $text-dark-secondary, transparent 10%) 0 20%/180% 0.1rem repeat-x, linear-gradient(90deg, $text-dark-secondary, transparent 20%) 0 80%/150% 0.2rem repeat-x, linear-gradient(90deg, $text-dark-secondary, transparent 5%) 0 65%/100% 0.2rem repeat-x, linear-gradient(90deg, $text-dark-secondary, transparent 5%) 0 40%/220% 0.2rem repeat-x
    overflow: hidden
    box-shadow: inset 0 0 60px 0 rgba(0, 0, 0, 0.1)
    text-align: center
    transform: rotate(-45deg)
  &__icon
    width: 35px
    animation: moveRocket 0.4s linear alternate infinite
    & > g > path
      &:nth-child(1)
        animation: moveFlames 0.08s linear alternate infinite
      &:nth-child(2)
        animation: moveFlames 0.06s linear alternate infinite
      &:nth-child(3)
        animation: moveFlames 0.05s linear alternate infinite

@keyframes moveParticles
  100%
    background-position-x: -200rem

@keyframes flashing
  0%
    opacity: 1
  100%
    opacity: 0.4

@keyframes moveFlames
  0%
    transform: translate(-2%, 2%)
  100%
    transform: translate(12%, -12%)

@keyframes moveRocket
  0%
    transform: translate(0%, 0%) rotate(45deg)
  20%
    transform: translate(3%, 1%) rotate(45.5deg)
  50%
    transform: translate(4%, 6%) rotate(45deg)
  70%
    transform: translate(6%, 2%) rotate(45.5deg)
  100%
    transform: translate(8%, -8%) rotate(45deg)
