@import '../../../styles/vars.sass'

.project-notification
  padding: 36px
  padding-top: 0
  padding-bottom: 20px
  &:last-child
    padding-bottom: 36px
  &__inner
    width: 100%
    background: rgba(61, 180, 41, 0.1)
    padding: 18px
    border-radius: 18px
    font-weight: 500
    line-height: 120%
    border: 5px solid rgba(61, 180, 41, 0.3)
    &_warning
      background: rgba(180, 41, 41, 0.1)
      border: 5px solid rgba(180, 41, 41, 0.3)

@media (max-width: $tablet-width)
  .project-notification
    padding: 20px
    padding-top: 0
    padding-bottom: 12px
    &:last-child
      padding-bottom: 20px
    &__inner
      border-radius: 20px

@media (max-width: $mobile-width)
  .project-notification
    padding: 14px
    &:not(:first-child)
      padding-top: 0
      padding-bottom: 14px
    &__inner
      border-radius: 18px
      padding: 12px
