@import "../../styles/vars"

.referrer-item
  width: 100%
  padding: 18px 36px
  display: grid
  grid-template-columns: 2.5fr 1.5fr 1.5fr
  align-items: center
  border-bottom: 1px solid $outline
  transition: all 0.25s
  &:last-child
    border-bottom: none
    padding-bottom: 36px
  &:hover
    background: $dark-hover
  &__title
    display: flex
    align-items: center
    font-weight: 600
    font-size: 24px
    line-height: 120%
  &__logo
    width: 60px
    height: 60px
    border-radius: 14px
  &__info
    display: flex
    gap: 8px
  &__info-project
    display: flex
    gap: 18px
  &__key
    font-weight: 400
    color: $text-light-secondary
  &__value
    font-weight: 500

@media (max-width: $tablet-width)
  .referrer-item
    padding: 20px 18px
    grid-template-columns: 2fr 1fr 1fr
    &:first-child
      padding-top: 9px
    &:last-child
      padding-bottom: 20px
    &__info-project
      gap: 10px
    &__logo
      width: 45px
      height: 45px
    &__key,
    &__value
      font-size: 13px

@media (max-width: $mobile-width)
  .referrer-item
    padding: 18px 14px
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 14px
    &__info-project
      width: 100%
    &__info
      max-width: 300px
      width: 100%
      display: grid
      grid-template-columns: 1fr 1fr
    &__info-project
      gap: 14px
    &__key,
    &__value
      font-size: 14px
