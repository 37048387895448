$background-color: #232647
$text-primary: #E6EDF1
$text-secondary: #AEAEAE
$text-dark: #6D6F71
$text-active: #00BFFF
$text-dark-secondary: #485066
$text-light-secondary: #92929E
$white: #fff

$background-primary: #0F1216
$background-hover: #1D2024
$background-gradient: linear-gradient(180deg, #091225 0%, #0B0E14 100%)

$btn-primary-color: #1649FF
$btn-hover-color: #3662FE
$btn-gradient: linear-gradient(90deg, #005BE7 13.88%, #004DC4 43.14%, #0003c0 85.14%)
$toggle-switch-color: #0158DF
$checkbox-checked-color: #0083E0

$danger: #DE4343
$warning: #F2C94C
$success: #3DB429
$active: #004DC4
$active-lighter: #00BFFF
$light: #E6EDF1
$dark: #0A132C
$dark-hover: #16203A
$secondary: #485166
$secondary-lighter: #69697E
$secondary-darker: #1B243B
$outline: #212B44

$small-mobile-width: 460px
$mobile-width: 639px
$tablet-width: 960px
$small-desktop-width: 1320px
$small-desktop-width-header: 1450px

$t-gray: #BBC2CA
$t-gray-400: rgba(255, 255, 255, 0.38)
$t-gray-100: rgba(255, 255, 255, 0.1)
$t-purple: #B358FB
$t-purple-pale: #9baefb
$t-blue-pale: #627eea
$t-blue: #0098FF
$t-black: #050D17
$t-red: #B83E3E
$t-white: #EEF9FC
$t-green: #10AA44
$t-cyan: #01C6FF
$t-stroke: rgba(88, 101, 121, 0.24)
