@import '../../../styles/mixins'
@import '../../../styles/vars'
.claim-modal
  display: flex
  max-width: 390px
  flex-direction: column
  &__title
    font-size: 24px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 32px
    &._text-green
      color: $t-green
    &._text-red
      color: $t-red
  &__subtitle
    font-size: 12px
    line-height: 16px
    font-weight: 500
    color: rgb(187, 194, 202)
    text-transform: uppercase
    &-span
      text-transform: uppercase
      color: $t-green
      background: rgba(16, 170, 68, 0.3)
      border-radius: 6px
      padding: 3px
    &._text-green
      color: $t-green
    &._text-red
      color: $t-red
  &__amount
    font-size: 26px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 32px

.claim-modal-final
  display: flex
  max-width: 390px
  flex-direction: column
  padding: 20px 40px

.withdraw-modal
  display: flex
  max-width: 390px
  flex-direction: column
  &__title
    font-size: 24px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 32px
    &._text-green
      color: $t-green
    &._text-red
      color: $t-red
  &__subtitle
    font-size: 12px
    line-height: 16px
    font-weight: 500
    color: rgb(187, 194, 202)
    text-transform: uppercase
    margin-bottom: 20px
    &-span
      text-transform: uppercase
      color: $t-green
      background: rgba(16, 170, 68, 0.3)
      border-radius: 6px
      padding: 3px
    &._text-green
      color: $t-green
    &._text-red
      color: $t-red
  &__amount
    font-size: 26px
    font-weight: 500
    line-height: 1.25
    margin-bottom: 12px
