@import '../../../styles/vars.sass'

.project-item
  position: relative
  z-index: 1
  border: 1px solid $outline
  border-radius: 20px
  background: $dark
  &__head
    height: 150px
    border-bottom: 1px solid $outline
    background-position: center
    background-repeat: no-repeat
    background-size: 100% 100%
    display: block
    padding: 20px
  &__tags
    display: flex
    justify-content: space-between
  &__body
    height: calc(100% - 150px - 90px) // &__head height, &__activities height чтобы высота карточек в ряду была одинаковая
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 20px
  &__row
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
    &:last-child
      margin-bottom: 0
    &-group
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
  &__title
    font-weight: 600
    font-size: 24px
    line-height: 120%
  &__stable
    width: 30px
    height: 30px
    border-radius: 50%
  &__links
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 18px
  &__link
    display: flex
    align-items: center
    &-icon
      width: 24px
      height: 24px
    &-title
      text-transform: capitalize
      font-weight: 600
      color: #6E7484
      font-size: 14px
      line-height: 140%
      margin-left: 4px
  &__info
    width: 100%
    &-item
      display: flex
      justify-content: space-between
      margin-bottom: 10px
      &:last-child
        margin-bottom: 0
    &-key
      color: $secondary
    &-value
      font-weight: 600
    &-key,
    &-value
      font-size: 16px
      line-height: 140%
  &__progress,
  &__button
    width: 100%

@media (max-width: $tablet-width)
  .project-item
    &__stable
      width: 20px
      height: 20px
    &__head
      height: 140px
      padding: 10px
    &__title
      font-size: 18px
    &__body
      height: calc(100% - 140px - 65px) // &__head height, &__activities height чтобы высота карточек в ряду была одинаковая
      padding: 10px
    &__info
      &-key
        color: $text-light-secondary
        font-weight: 600
        font-size: 14px
      &-value
        font-size: 14px
    &__button
      border-radius: 14px

@media (max-width: $mobile-width)
  .project-item
    &__head
      height: 100px
      padding: 14px
    &__title
      font-size: 20px
    &__body
      padding: 14px
    &__link
      &-icon
        width: 22px
        height: 22px
      &-title
        font-weight: 500
        font-size: 12px
    &__row
      margin-bottom: 14px
    &__button
      font-size: 13px
