@import "../../styles/vars"

.input-number
  width: 100%
  font-weight: 600
  font-size: 16px
  line-height: 100%
  letter-spacing: 0.02em
  color: $text-primary
  &::placeholder
    font-weight: 400
    font-size: 16px
    line-height: 100%
    letter-spacing: 0.02em
  &__container
    position: relative
    width: 100%
    &._blank
      padding: 0
      border-radius: 0
      border: none
      & + ._have-icon
        padding-right: 36px
    &._outline
      padding: 16px 18px 16px 18px
      border-radius: 18px
      border: 1px solid $text-dark-secondary
      & + ._have-icon
        padding-right: 46px
  &__icon
    width: 24px
    height: 24px
    position: absolute
    &._edit
      cursor: pointer
    &._blank
      right: 0px
      top: -2px
    &._outline
      right: 18px
      top: 50%
      transform: translateY(-50%)

@media (max-width: $mobile-width)
  .input-number
    &::placeholder
      font-weight: 500
      font-size: 14px
