@tailwind base;
@tailwind components;
@tailwind utilities;

.h1 {
  @apply text-xl font-medium leading-none;
}
.h3 {
  @apply text-2xl font-medium;
}
.h3b {
  @apply text-2xl font-bold;
}
.h4 {
  @apply text-xs font-medium uppercase;
}
.p1 {
  @apply text-xl;
}
.p2 {
  @apply text-base;
}
.p2b {
  @apply text-base font-bold;
}
.p3 {
  @apply text-sm font-normal;
}
.p4 {
  @apply text-xs font-medium;
}
.p5 {
  @apply text-xxs font-medium;
}
