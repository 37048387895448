@import ../styles/vars

.outer-link
    overflow: hidden
    display: flex
    align-items: center
    color: white
    opacity: 0.4
    transition: .3s
    cursor: pointer
    font-size: 20px
    line-height: 100%
    font-weight: 500
    letter-spacing: -0.04em
    max-width: fit-content
    line-height: 145%
    gap: 10px
    &__arrow
        width: 12px
        height: 12px
        object-fit: contain
        transition: .3s
    &:hover
        opacity: 1

@media (max-width: $small-desktop-width)
    .outer-link
        font-size: 18px

@media (max-width: $tablet-width)
    .outer-link
        line-height: 115%
        font-size: 14px
