@import '../../styles/vars.sass'

.project-timeline
  transition: max-height 0.3s
  &__inner
    display: flex
  &__button
    display: flex
    justify-content: space-between
    align-items: center
    &-arrow
      transition: transform 0.3s
      &._open
        transform: rotate(180deg)
  &__header
    display: flex
    margin-top: 32px
    margin-bottom: 14px
  &__title
    font-weight: 700
    text-transform: uppercase
    font-size: 14px
    line-height: 100%
  &__column-title
    width: 50%
    font-weight: 600
    text-transform: uppercase
    color: $text-light-secondary
    font-size: 13px
    line-height: 100%
  &__item
    width: 100%
    &-title
      &._active
        background: $active
        color: $light
      &._text-active
        color: $light
    &:last-child
      width: auto
      position: relative
      & .project-timeline__item-date
        position: absolute
        text-align: right
        min-width: 200px
        right: 6px
        top: 34px
    &-row
      display: flex
      align-items: center
    &-title
      text-transform: uppercase
      padding: 0 6px 0 8px
      height: 24px
      display: flex
      align-items: center
      border-radius: 10px
      font-weight: 600
      font-size: 13px
      line-height: 140%
      color: $secondary
    &-line
      width: 100%
      height: 1px
      background: $outline
      &._active
        background: $active
    &-dates
      margin-top: 10px
      padding-left: 8px
    &-date
      font-size: 14px
      line-height: 140%
      margin-bottom: 4px
      color: $secondary
      &._active
        color: $light
      &:last-child
        margin-bottom: 0

@media (max-width: $tablet-width)
  .project-timeline
    &__item
      &-date
        margin-bottom: 10px

@media (max-width: $mobile-width)
  .project-timeline
    background: $dark
    border: 1px solid $outline
    border-radius: 20px
    max-height: 50px
    overflow: hidden
    &._open
      max-height: 800px // хорошо бы auto, но тогда transition не работает.800px это больше чем необходимо.
    &__inner
      padding: 14px
      flex-direction: column
    &__item
      display: flex
      align-items: baseline
      margin-bottom: 23px
      &:last-child
        margin-bottom: 0
        & .project-timeline__item-date
          position: static
          text-align: left
          min-width: unset
      &-title
        padding: 0px
      &._active &
        &-title
          background: none
      &-row
        width: 50%
      &-line
        display: none
        background: none
        margin: 0px 12px
        &._active
          background: none
          display: block
          border-bottom: 2px dashed $secondary
      &-dates
        margin-top: 0px
        padding-left: 0px
        width: 50%
