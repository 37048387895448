@import '../styles/vars'

.main
  &__buttons
    display: flex
    gap: 18px
  &__launch
    background: linear-gradient(266.88deg, #0A1F70 -19.64%, #1649FF 140.38%)
    border-radius: 40px
    width: 100%
    min-height: 210px
    text-align: center
    &-inner
      display: flex
      flex-direction: column
      align-items: center
      padding: 40px 222px
    &-title
      font-weight: 700
      font-size: 32px
      line-height: 120%
      letter-spacing: -0.01em
      margin-bottom: 10px
    &-desc
      width: 100%
      font-size: 16px
      line-height: 140%
      letter-spacing: -0.01em
      margin-bottom: 20px
    &-btn
      padding: 12px 28px 12px 28px
      display: flex
      align-items: center
      background: #080B0F
      text-transform: uppercase
      border-radius: 14px
      font-weight: 700
      font-size: 13px
      line-height: 100%
      letter-spacing: -0.01em
      transition: all 0.3s
      &:hover
        color: $active
        & *
          fill: $active
      &-icon
        margin-right: 8px
        & *
          transition: all 0.3s
  &__audit
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 40px
    &-icon
      width: 71px
      height: 80px
      margin-bottom: 17px
    &-title
      text-transform: uppercase
      text-align: center
      line-height: 140%
      letter-spacing: 0.05em
    &-desc
      text-transform: uppercase
      text-align: center
      font-size: 36px
      line-height: 120%
      letter-spacing: -0.01em

@media (max-width: $small-desktop-width)
  .main
    &__launch
      &-inner
        padding: 25px 80px
    &__audit
      padding: 28px 22px
      border: 2px solid rgba(255, 255, 255, 0.5)
      background: url(../../assets/img/audit-bg-rocket.svg) no-repeat 45px 20%, url(../../assets/img/audit-bg-earth.svg) no-repeat bottom right, url(../../assets/img/audit-bg-route.svg) no-repeat top left

@media (max-width: $tablet-width)
  .main
    &__launch
      &-inner
        padding: 30px 108px
      &-title
        font-size: 24px
        margin-bottom: 10px
      &-desc
        font-size: 14px
        margin-bottom: 20px

@media (max-width: $mobile-width)
  .main
    &__buttons
      flex-direction: column
      width: 100%
    &__launch
      border-radius: 20px
      &-inner
        padding: 14px
        text-align: left
      &-title
        line-height: 140%
        margin-bottom: 14px
      &-desc
        margin-bottom: 28px
    &__audit
      border-radius: 20px
      background: none
