@import "../../styles/vars"

.referral-info
  width: 100%
  padding: 18px 36px
  display: grid
  grid-template-columns: 3fr 4fr
  align-items: center
  border-bottom: 1px solid $outline
  &:last-child
    border-bottom: none
  &__stats
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
  &__key
    display: none
    font-weight: 700
    font-size: 13px
    text-transform: uppercase
    color: $secondary
    margin-bottom: 14px
  &__value
    letter-spacing: 0.02em
    &._green
      color: $success
    &._red
      color: $danger
    &._blank
      color: $text-dark-secondary

@media (max-width: $small-desktop-width)
  .referral-info
    grid-template-columns: 2fr 4fr
    &__value
      font-size: 14px

@media (max-width: $tablet-width)
  .referral-info
    padding: 20px
    grid-template-rows: 1fr 1fr
    grid-template-columns: 1fr
    gap: 20px
    &__key
      display: block
    &__value
      font-size: 14px

@media (max-width: $mobile-width)
  .referral-info
    padding: 14px
    grid-template-rows: 1fr 4fr
    gap: 6px
    margin-bottom: 28px
    border: 1px solid $outline
    border-radius: 20px
    background: $dark
    &:last-child
      border-bottom: 1px solid $outline
    &__stats
      grid-template-rows: 1fr 1fr 1fr
      grid-template-columns: 1fr
      gap: 8px
    &__item
      display: flex
      justify-content: space-between
    &__key,
    &__value
      font-size: 14px
      line-height: 140%
      font-weight: 600
      text-transform: capitalize
      letter-spacing: 0.01em
    &__key
      margin-bottom: 0
      display: block
