@import '../../styles/vars.sass'

.project-fill
  &__row
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 6px
  &__title
    font-size: 14px
    line-height: 140%
    color: $text-light-secondary
  &__percent
    font-weight: 600
    font-size: 14px
    line-height: 140%
  &__bar
    background: $secondary-darker
    border-radius: 8px
    width: 100%
    height: 8px
    &-active
      width: 33%
      border-radius: 8px
      height: 100%
      background: $active
  &__raise
    font-weight: 600
    font-size: 14px
    line-height: 140%
    color: $text-light-secondary
    display: flex
    gap: 6px
    align-items: center
  &__fill
    font-weight: 600
    font-size: 14px
    line-height: 140%
    color: $text-light-secondary
    display: flex
    align-items: center
    gap: 6px
    &-icon
      width: 22px
      height: 22px
  &__filled
    width: 100%
    font-weight: 500
    font-size: 16px
    line-height: 140%
    color: $secondary
    text-align: center

@media (max-width: $mobile-width)
  .project-fill
    &__title
      font-weight: 600
      color: $text-primary
    &__row:last-child
      display: block
    &__icon
      display: none
    &__bar
      margin-bottom: 10px
    &__raise
      margin-bottom: 8px
