@import "../../styles/vars"

.file-input
  max-width: 100%
  display: none
  &__label
    cursor: pointer
    display: flex
    align-items: center
    gap: 8px
  &__icon
    min-width: 30px
    width: 30px
    height: 30px
  &__filename
    padding: 7px 0px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    &._empty
      color: $secondary
