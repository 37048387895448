.appear-container
    opacity: 0
.visible
    opacity: 1
    animation: appear 1s normal

@keyframes appear
    0%
        opacity: 0
    100%
        opacity: 1
