@import '../../../styles/vars.sass'

.project-refund
  &__timer
    margin-bottom: 20px
  &__inner
    max-width: 400px
  &__loader
    height: 180px
  &__row
    margin-bottom: 36px
    &-desc
      font-size: 14px
      margin-top: 18px
      letter-spacing: 0.02em
      color: $text-light-secondary
      &._light
        color: $danger
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 18px
  &__back
    cursor: pointer
    font-size: 14px
    letter-spacing: 0.04em
    margin: 0 auto
    &-icon
      width: 20px
      margin-right: 7px
      & path
        &:first-child
          fill: $text-primary
        &:last-child
          stroke: $text-primary
  &__desc
    font-weight: 400
    line-height: 140%
    & > span
      font-weight: 700
  &__input
    font-family: Inter
    cursor: text
    height: 100px
    width: 100%
    background-color: $dark
    resize: none
    font-size: 16px
    line-height: 120%
    letter-spacing: 0.02em
    width: 100%
    color: $text-primary
    &-container
      padding: 12px
      border-radius: 18px
      margin-bottom: 18px
      border: 1px solid $secondary
    &::-webkit-scrollbar
      width: 5px
    &::-webkit-scrollbar-track
      border-radius: 5px
    &::-webkit-scrollbar-thumb
      background: $text-dark-secondary
      border-radius: 5px
    &::-webkit-scrollbar-thumb:hover
      background: $text-light-secondary
    &::placeholder
      color: $secondary
      font-size: 16px
      line-height: 100%
      letter-spacing: 0.02em
  &__checkbox
    font-weight: 500
    display: flex
    align-items: center
    gap: 10px
    &-icon
      width: 24px
      height: 24px
      opacity: 0
      transition: opacity 0.3s
      &._visible
        opacity: 1
  &__amount
    font-weight: 600
    letter-spacing: 0.02em
    &-container
      border-radius: 18px
      padding: 16px 18px
      background: $secondary-darker
  &__button
    display: flex
    flex-direction: column
    gap: 18px
  &__success
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px
    &-title
      color: $text-light-secondary
      font-weight: 600
      font-size: 24px
      line-height: 140%
      text-align: center
      & > span
        color: $text-primary
    &-loader
      width: 100%
  &__links
    display: flex
    gap: 8px
    &._margin
      margin-bottom: 36px
  &__link
    font-weight: 500
    color: $text-active
    padding-bottom: 2px
    border-bottom: 1px solid $text-active
    &._margin
      margin-bottom: 36px
  &__claim
    width: fit-content
    margin: 0 auto
    padding-top: 36px
    &-desc
      font-weight: 500
      font-size: 14px
      font-style: italic
      color: $text-dark-secondary
      margin-top: 8px
  &__chain-selector
    width: 100%
    &-inner
      width: fit-content

@media (max-width: $mobile-width)
  .project-refund
    &__loader
      width: 100%
    &__success
      text-align: left
    &__claim
      width: 100%
      margin: 0
    &__links
      flex-direction: column
    &__chain-selector
      &-inner
        width: 100%
