@import '../../../styles/vars.sass'

.project-item-activity
  border: 1px solid $outline
  border-radius: 10px
  display: flex
  &__list
    display: flex
    gap: 10px
    padding: 20px
  &__message
    width: 100%
    color: $text-light-secondary
    min-height: 52px
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    gap: 5px
  &__item
    width: 87px
    border-radius: 10px
    border: 1px solid $outline
    padding: 8px
    padding-left: 0px
    display: flex
    justify-content: center
    align-items: center
    &._now
      border: 1px solid $active-lighter
      background: rgba(0, 191, 255, 0.2)
      box-shadow: 0px 0px 22px rgba(0, 191, 255, 0.2), inset 0px 0px 10px rgba(255, 255, 255, 0.05)
    &._ended
      opacity: 0.6
  &__text
    max-width: 30px
    font-weight: 600
    font-size: 14px
    text-align: center
    text-transform: capitalize
    color: $text-light-secondary
    &._now
      color: $active-lighter
  &__img
    width: 34px
    height: 34px
    & > *
      fill: $active-lighter
    &._not-started
      opacity: 0.7
    &._ended
      opacity: 0.6

@media (max-width: $tablet-width)
  .project-item-activity
    &__list
      padding: 10px
      gap: 7px
    &__message
      font-size: 14px
      min-height: 47px
    &__item
      width: 68px
      padding: 6px
      padding-left: 0px
      min-height: 47px
    &__img
      width: 30px
      height: 30px

@media (max-width: $mobile-width)
  .project-item-activity
    &__list
      gap: 6px
    &__message
      min-height: 36px
    &__item
      width: 62px
      border-radius: 9px
      padding: 5px 7px
      padding-left: 2px
      min-height: 36px
    &__text
      font-weight: 500
      font-size: 12px
    &__img
      width: 24px
      height: 24px
