@import '../../../styles/mixins'
@import '../../../styles/vars'

.select-line
  &__content
    display: grid
    grid-template-columns: repeat(5, 1fr)
    gap: 4px
    padding: 4px
    border-radius: 10px
    background: rgba(88,101 ,121 ,0.24 )
  &__item
    text-align: center
    +text-p3()
    color: $t-white
    cursor: pointer
    border-radius: 8px
    &._bg
      background: $t-blue
    &._disabled
      opacity: .4
