@import '../../../styles/vars.sass'

.ido-claim
  max-width: fit-content
  &__loader
    height: 180px
  &__title
    font-weight: 700
    font-size: 13px
    line-height: 140%
    text-transform: uppercase
    margin-bottom: 18px
  &__actions
    position: relative
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    &._hidden
      display: none
  &__metamask
    cursor: pointer
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    // width: 110%
    margin-bottom: 18px
    &._hidden
      display: none
  &__title
    margin-bottom: 0
  &__date
    font-size: 13px
    line-height: 18.2px
    font-weight: 500
    color: $secondary
  &__info
    width: fit-content
    margin-bottom: 18px
    display: flex
    border: 2px solid $secondary
    border-radius: 18px
    background: rgba(27, 36, 59, 0.5)
  &__column
    min-width: fit-content
    padding: 18px
    height: 100%
    &:nth-child(2)
      border-right: 2px solid $secondary
      border-left: 2px solid $secondary
    &-title
      text-transform: uppercase
      font-size: 13px
      line-height: 100%
      font-weight: 700
      color: $text-light-secondary
      margin-bottom: 12px
    &-value
      font-size: 16px
      line-height: 100%
      letter-spacing: 0.02em
      font-weight: 600
      color: #E6EDF1
      &._transparent
        color: $text-light-secondary
  &__amount
    display: none
    font-weight: 600
    font-size: 13px
    line-height: 100%
    width: 100%
    text-align: center
    padding: 14px 0px
    text-transform: uppercase
    color: $secondary
  &__icon
    &._copy
      cursor: pointer
      width: 28px
      height: 28px
      margin-left: 6px
      margin-right: 6px
      border-radius: 8px
      padding: 2px
      transition: background 0.2s
      &:hover
        background: $outline
  &__notice
    position: absolute
    top: -35px
    opacity: 0
    border-radius: 11px
    background: $secondary-darker
    padding: 8px 16px 9px
    font-weight: 700
    line-height: 100%
    font-size: 13px
    letter-spacing: -0.01em
    transition: opacity 0.2s
    &._visible
      opacity: 1

@media (max-width: $mobile-width)
  .ido-claim
    max-width: unset
    &__loader
      height: 270px
      width: 100%
    &__header
      width: 100%
      margin-bottom: 28px
    &__title
      font-size: 14px
      line-height: 100%
    &__date
      display: none
    &__info
      flex-direction: column
      border: none
      background: none
      margin-bottom: 18px
    &__column
      padding: 0px
      margin-bottom: 18px
      &:nth-child(2)
        border-right: none
        border-left: none
        margin-bottom: 0px
      &:last-child
        display: none
      &-value
        font-size: 20px
        line-height: 140%
    &__amount
      display: block
