@import "../../styles/vars"

.checkbox
  cursor: pointer
  display: flex
  align-items: center
  gap: 10px
  &__input
    display: none
    &:checked + div
      background: $checkbox-checked-color
      & path
        fill: $white
  &__fake
    min-width: 24px
    width: 24px
    height: 24px
    background: $secondary-darker
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
    &-icon
      width: 13px
      height: 10px
      & path
        fill: $secondary-darker
