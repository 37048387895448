@import "../../styles/vars"

.coinex-page
  position: relative
  overflow-x: hidden
  &__inner
    max-width: 982px
  &__block
    display: flex
    align-items: center
    justify-content: center
    gap: 18px
    margin-bottom: 180px
  &__button
    width: 205px
  &__button-block
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 50px
  &__content
    display: flex
    gap: 20px
  &__background
    position: absolute
    top: 105px
    pointer-events: none
    z-index: -2
    &.left
      width: 285px
      top: 30px
    &.right
      right: 0
    &.mobile
      width: 666px
      height: 630px
      opacity: 0.2
      top: -30px

@media (max-width: $tablet-width)
  .coinex-page
    &__block
      margin-bottom: 100px
    &__content
      flex-wrap: wrap
      gap: 40px
    &__background
      &.mobile
        width: 640px
        height: 640px
        opacity: 0.2
        top: -30px
        right: -10%

@media (max-width: $mobile-width)
  .coinex-page
    &__button-block
      width: 100%
    &__button
      width: 100%
    &__background
      &.mobile
        width: 600px
        height: 600px
        right: -20%
