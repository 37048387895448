@import '../../../styles/vars.sass'

.project-wizard-mobile
  border: 1px solid $outline
  background: $dark
  border-radius: 20px
  margin-bottom: 28px
  &__row
    padding: 14px
    border-bottom: 1px solid $outline
    padding-bottom: 18px
    &:last-child
      border-bottom: none
