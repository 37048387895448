@import '../../styles/vars.sass'

.wallet
  position: relative
  &__inner
    display: flex
    gap: 10px
    align-items: center
    padding: 0px 4px
  &__img
    height: 24px
    width: 24px
  &__chain-img
    height: 24px
  & > ._blank
    background: transparent
    border: 2px solid $outline
    &:hover
      background: $dark-hover
