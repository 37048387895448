@import "../../styles/vars"

.header
  position: relative
  z-index: 10
  width: 100%
  &__logo
    position: relative
    z-index: 10
  &__inner
    margin: 0 auto
    max-width: 1600px
    padding: 20px
    display: flex
    align-items: center
    justify-content: space-between
    gap: 15px
  &__actions
    display: flex
    align-items: center
    gap: 18px
  &__btn
    position: relative
    cursor: pointer
    width: 20px
    height: 16px
    display: flex
    flex-direction: column
    justify-content: space-between
  &__btn-row
    width: 20px
    height: 2px
    background-color: $active-lighter
    border-radius: 15px
    transition: transform 0.3s
    &._opened
      &:first-child
        position: absolute
        transform: rotate(45deg)
        top: 7px
      &:last-child
        position: absolute
        transform: rotate(-45deg)
        bottom: 7px
      &:nth-child(2)
        opacity: 0

@media (max-width: $mobile-width)
  .header
    &__inner,
    &__actions
      gap: 8px
    &__logo
      width: 110px

@media (max-width: $small-mobile-width)
  .header
    &__inner
      gap: 0
    &__logo
      width: 90px
