@import '../../styles/vars.sass'

.button
  font-weight: 500
  font-size: 16px
  line-height: 100%
  letter-spacing: 0.02em
  width: 100%
  min-height: 48px
  border-radius: 18px
  transition: color 0.2s, background 0.2s, transform 0.2s
  &__inner
    padding: 0 11px
  &._filled
    background: $btn-gradient
    background-size: 200%
    &:hover
      background-position-x: 90%
      transition: color 0.2s, background 0.2s, transform 0.2s
  &._dark
    background: $secondary-darker
    min-height: auto
    padding: 11px
    &:hover
      color: $active
      background: $dark-hover
  &._blank
    background: transparent
    border: 2px solid $text-dark-secondary
    &:hover
      background: $dark-hover
  &._disabled
    background: #0A132C
    color: rgba(149, 181, 191, 0.3)
    border: 2px solid rgba(149, 181, 191, 0.2)
  &._s
    font-weight: 500
    font-size: 14px
    line-height: 140%
    min-height: auto
    & > .button__inner
      padding: 5px 15px

@media (max-width: $tablet-width)
  .button
    font-size: 14px
    border-radius: 18px

@media (max-width: $mobile-width)
  .button
    font-size: 16px

@keyframes name
  0%

  100%
